
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.page .user-page-title,
.page .page-title
  padding: 0em 3em 0em 2em
  display: flex
  position: relative
  height: 7em

  &>.title>.action
    @include clear-button

    border-radius: 100%
    margin: auto 0em
    padding: 0.7em
    display: flex

    &.white
      background: #ffffff

      .ui.svg
        font-size: 1.7em

    .ui.svg
      font-size: 2.3em

  .title
    display: flex
    flex: 1
    overflow: hidden
    margin-right: 1em
    
    .text
      margin: auto 0em
      margin-left: 1.26em
      flex: 1
      display: flex
      overflow: hidden

      &>.data-additional
        display: flex

        .ui.stick
          margin: 0em 0em auto 1em

      &>.data
        overflow: hidden

        &>.data-title
          flex: 1
          display: flex

          &>span
            @include text-ellipsis
            font-size: 1.4em
            color: #34335C
            font-weight: bold

        &>.data-subtitle
          flex: 1
          @include text-ellipsis
          color: #34335C
          font-weight: 500
          font-size: 1em
  
  .btn-action
    font-size: 1em
    padding: 0.6em 1.8em 0.8em 1.8em
    white-space: nowrap
    margin: auto 0em
    margin-right: 2em

  .ui.avatar
    box-shadow: 0em 0em 0em 0.18em rgba(246, 248, 250, 1) !important
