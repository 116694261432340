.form.add.checklist-item-add
  .ui.button
    white-space: pre-line

  .ui.form.textarea
    &>.body
      border: 0.07em solid #C3CFD9
      border-radius: 0.3em
      background-color: #ffffff

      textarea
        background: transparent
        min-height: unset
        cursor: pointer
        padding: 0.4em 0.5em
        border-color: transparent

  .ui.form.textarea>.footer
    margin-bottom: 0.5em