.item.checklists-block-item,
.item.checklist-stub
  background-color: #ffffff
  // overflow: hidden

  &>.body .items-list
    margin-bottom: 1em

  &.lock
    &>.body .items-list
      border-radius: 0.3rem
      border: $input-border-style
      background-color: #F6F8FA
      padding: 0.5em

    > .header > .name
      .row-wrapper
        padding-left: calc(1.25rem)

      .options-rectangle
        padding-left: 0
        margin-left: calc(-1.25rem)

  &.completed
    .caunter
      color: #61CE4C !important

  &.move
    background-color: darken(#E9EFF4, 4%)

  .dragg-shadow
    margin: 0.6em 0
    background-color: fade-out(#000000, 0.95)
    transition: 0.5s all
    border-radius: 0.2rem

    &:not(:last-child)
      margin-bottom: 0.5em

  &:not(:last-child)
    margin-bottom: 1.5em

  &>.header
    display: flex
    position: relative

    &>.icon
      font-size: 1.3em
      margin: 0.3em 0.3em auto 0em

    &>.name
      flex: 1
      white-space: nowrap
      display: flex
      margin: auto 0em
      position: relative
      min-width: 0

      & .options-rectangle
        padding: 0 6px
        height: 30px
        border-radius: 3px
        display: flex
        align-items: center
        min-width: 0

        &__buttons
          display: flex
          visibility: hidden
        &:hover
          background: #e9eff4
          & .options-rectangle__buttons
            visibility: visible
        &__item
          width: 24px
          height: 24px
          border-radius: 3px
          align-items: center
          justify-content: center
          cursor: pointer
          display: flex
          &:hover
            background: #cfd8e1
        &__name
          display: inline-block
          padding: 2px
          max-width: 400px
          text-overflow: ellipsis

          &:hover
            background: #cfd8e1
          
      span,
      .caunter
        font-size: 1.1em
        color: #34335C

        &.transparent
          opacity: 0

      span
        @include text-ellipsis
        margin: auto 0em
      
      .caunter
        padding-left: 0.2em
        margin: auto 0em

      .row-wrapper
        position: relative
        display: flex
        min-width: 0
        flex-wrap: wrap

      .lock
        flex-shrink: 0
        margin: 0 3px
        font-size: 1.25rem
        line-heigth: 1

        .ui.svg
          display: block

      .favourite
        margin: auto 0 auto 0.2em
        flex-shrink: 0

      .favourite-icon
        display: block
        color: #FFCC5B

      .signed-label
        margin-left: 0.5em
        background: $green-color
        border-radius: 2px
        color: $white-color
        padding: 4px
        font-size: .85em
        height: 1.96em

      .signed-label--dedicated
        margin-top: auto
        margin-bottom: 0.1em

      .btn-toggle
        margin: auto 0em

        .ui.svg
          font-size: 0.9em
          margin: auto 0.5em

    .form-edit-name
      position: absolute
      z-index: 1
      background-color: #ECF1F6
      top: -0.8em
      left: 1.7em
      padding: 0.5em
      border-radius: 0.2rem
      right: 0

    &>.controls
      display: flex
      align-items: center
      margin: 0 0em auto

      .action
        margin: auto -0.75rem auto 0.5em
        // margin: -0.4em -0.8em -0.4em 0.5em
        cursor: pointer
        padding: 0.4em 0.5em
        border-radius: 0.3em
        transition: 0.2s background-color

        &:hover
          background-color: $icon-button-background-color-hover

        .ui.svg
          font-size: 1.1rem

      .ui.button
        margin-left: 0.5em

  &>.body
    &,
    input
      cursor: pointer

    &>.controls
      padding-top: 0.5em

    &>.list
      &:not(:empty)
        padding-top: 0.5em

      @import './ChecklistItem.sass'
