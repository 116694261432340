
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.drop-select
  position: relative
  width: 100%

  // ::before
    // content: attr(data-content)
    // position: absolute
    // top: 0
    // right: 3em
    // bottom: 0
    // left: 0

    // white-space: nowrap
    // text-overflow: ellipsis
    // overflow: hidden

    // pointer-events: none

  &:disabled,
  &[disabled]
    opacity: 0.6

  .arrow
    position: absolute
    top: 0.9em
    right: 0.9em
    pointer-events: none

  select
    -webkit-appearance: none
    -moz-appearance: none

    background-color: #ffffff
    display: block
    width: 100%
    border: $input-border-style
    padding: 0.7em 1em
    border-radius: 0.3rem
    font-family: 'NunitoSans', sans-serif
    font-size: 0.9em
    color: #34335C
    line-height: 1.45em
    cursor: pointer

    &.with-placeholder:required:invalid
      color: $placeholder-color

    &.readonly
      opacity: 1
      cursor: pointer
      pointer-events: initial

    padding-right: 3em

    text-overflow: ellipsis
    overflow: hidden !important
    white-space: nowrap

.ui.drop-select--is_error
  select
    color: $error-color
    border-color: $error-color
