
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.time-picker
  padding: 1em 0em

  &>.body
    display: flex
    justify-content: center

    .separator
      margin: auto 0.4em
      font-size: 2.5em
      color: $primary-color

    .select-wrap
      position: relative
      flex: 1
      text-align: center
      text-align-last: center
      border-bottom: 0.1em solid $bali-hai-color
      padding: 0.3em 0.5em

      span
        color: $primary-color
        font-size: 1.5em

      select
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        width: 100%
        height: 100%
        cursor: pointer
        opacity: 0
        z-index: 1

        -webkit-appearance: menulist-button