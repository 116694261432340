
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.edit-form-row
  position: relative

  &.change-color
    &.theme
      input
        color: $theme-color

  .input-box
    display: flex
    padding: 0.5em 0em

    .ui.svg:first-child
      font-size: 1em
      margin: auto 0.25em
      margin-left: 0em

    .ui.icon:first-child
      font-size: 1.3em
      margin: auto 0.25em
      margin-left: 0em

    .ui.input
      flex: 1

      &.no-radius input
        border-bottom-left-radius: 0em
        border-bottom-right-radius: 0em

      input
        font-size: 1em
        padding: 0.5em 0.8em

    .edit
      opacity: 0
      transition: 0.2s
      margin: auto 0em

  .controls
    position: absolute
    z-index: 2
    left: 0
    right: 0
    padding-top: 0.2em
    padding-left: 4em
    padding-bottom: 0.5em
    background-color: #ffffff
    box-shadow: 0em 0.4em 0.3em 0em fade-out(#000000, 0.95)

    .locations,
    .list-wrapper
      .list
        background-color: #F7F9FB
        position: relative
        margin-right: 0.5em
        top: -0.8em
        border: $input-border-style
        border-bottom-left-radius: 0.3rem
        border-bottom-right-radius: 0.3rem
        margin-bottom: 0.5em
      
    .locations .list
      .item
        font-weight: normal
        padding: 0.5em 1em
        cursor: pointer
        color: $primary-color
        transition: 0.2 opacity

        &:hover
          opacity: 0.7
        //   background-color: #F2F5F8

    .ui.button
      margin-right: 0.8em

  &:hover
    .input-box
      .edit
        opacity: 1

  &.edit
    .input-box
      .edit
        display: none

  &.textarea
    .controls
      padding-left: 0em

  &:not(.edit)
    .input-box
      cursor: pointer

      .ui.input input
        border-color: transparent
        background: transparent
        padding-left: 0.5em
        //font-weight: 600
        cursor: pointer
        @include text-ellipsis

        @include placeholder
          color: $placeholder-color
          //font-weight: normal
    
    .ui.icon:first-child
      font-size: 1.3em
      margin: auto 0.25em
      margin-left: 0em


  // &.chats .input-box .icon
    background-image: url(~@img/chats-dark.svg)
    // background-size: 1.5em
  
  // &.user .input-box .icon
    background-image: url(~@img/user-dark.svg)
    // background-size: 1.2em

  // &.phone .input-box .icon
    background-image: url(~@img/phone-dark.svg)
    // background-size: 0.9em

  // &.location .input-box .icon
    background-image: url(~@img/location-dark.svg)
    // background-size: 1.2em

  // &.checklist .input-box .icon
    background-image: url(~@img/checklist-dark.svg)
    // background-size: 1.2em