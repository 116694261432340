
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.select-multiple
  padding: 0em 0em
  position: relative

  &>.body
    background: #ffffff
    position: absolute
    z-index: 2
    top: calc(100% - 1px)
    left: 0
    right: 0
    border: 1px solid $input-border-color
    border-radius: 0em 0em 0.3rem 0.3rem
    box-shadow: 0em 0em 0.5em 0em fade-out(#000000, 0.9)

    .select-all
      padding: 0.7em 1em
      border-top: 1px solid $input-border-color
      font-size: 0.9em
      display: flex
      background-color: #F1F5F8

      .separator
        flex: 1

      label
        color: $primary-color
        cursor: pointer

      .close
        cursor: pointer
        color: #7F848B
        text-decoration: underline


    .find
      padding: 0.5em 0.7em
      margin: 0.5em
      display: flex
      align-items: center
      border: 1px solid $input-border-color
      border-radius: 0.3rem

      input
        border: unset
        background: unset
        padding-left: 0.7em

    .list
      max-height: 10em
      border-top: 1px solid #B2C0CC

      .item
        padding: 0.7em 1em
        font-size: 0.9em
        color: $primary-color
        cursor: pointer
        transition: 0.2s all
        display: flex

        label
          color: $primary-color
          cursor: pointer

        &:not(:first-child)
          border-top: 1px solid #B2C0CC
        
        &:hover
          background-color: #F1F5F8

  &.hide-search
    .find
      display: none

    .list
      border-top: none