
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
// TODO: move nested selectors from the namespace (.ui-menu-button) when pure-marking rules are removed
.ui-menu-button.ui-menu-button
  display: inline-flex
  padding: 0.4em
  border: none
  font-size: 1rem
  border-radius: 0.25em
  background-color: transparent
  transition: 0.2s background-color

  &:not(:disabled)
    cursor: pointer

  &:hover
    background-color: $icon-button-background-color-hover

  &:disabled
    opacity: 0.5

  &--size_big
    font-size: 1.1rem
