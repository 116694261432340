
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.users-counter
  border: 0.1em solid $primary-color
  min-width: 2.2em
  height: 2.2em
  border-radius: 10rem
  display: flex
  align-items: center
  font-size: 0.8em
  padding: 0em 0.3em

  span
    font-size: 0.7em
    color: $primary-color
    font-weight: 700
    margin: auto 
