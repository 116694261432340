.ui.icon
  &.arrow
    img
      mask-image: url(~@/assets/img/icon-masks/arrow-right.svg)
      mask-size: 0.9em 0.9em

    &.up
      transform: rotate(270deg)

    &.down
      transform: rotate(90deg)

  &.file
    &.secure img
      mask-image: url(~@/assets/img/icon-masks/file-secure.svg)
      mask-size: 0.85em 0.85em

  &.cloud-upload img
    mask-image: url(~@/assets/img/icon-masks/cloud-upload.svg)
    mask-size: 0.85em 0.85em

  &.download img
    mask-image: url(~@/assets/img/icon-masks/download.svg)
    mask-size: 0.85em 0.85em

  &.shape img
    mask-image: url(~@/assets/img/icon-masks/shape.svg)
    mask-size: 0.85em 0.85em

  &.resend img
    mask-image: url(~@/assets/img/icon-masks/resend.svg)
    mask-size: 0.85em 0.85em

  &.back img
    mask-image: url(~@/assets/img/icon-masks/back.svg)
    transform: rotate(180deg)
    mask-size: 0.85em 0.85em

  &.send
    img
      mask-image: url(~@/assets/img/icon-masks/send.svg)
      mask-size: 0.85em 0.85em

    &.o img
      mask-image: url(~@/assets/img/icon-masks/send-o.svg)

  &.emoji img
    mask-image: url(~@/assets/img/icon-masks/emoji.svg)
    mask-size: 0.85em 0.85em

  &.rename img
    mask-image: url(~@/assets/img/icon-masks/rename.svg)
    mask-size: 0.9em 0.9em

  &.chats img
    mask-image: url(~@/assets/img/icon-masks/chats.svg)
    mask-size: 0.9em 0.9em

  &.media img
    mask-image: url(~@/assets/img/icon-masks/media.svg)
    mask-size: 0.9em 0.9em

  &.user img
    mask-image: url(~@/assets/img/icon-masks/user.svg)
    mask-size: 0.9em 0.9em

  &.phone img
    mask-image: url(~@/assets/img/icon-masks/phone.svg)
    mask-size: 0.9em 0.9em

  &.location img
    mask-image: url(~@/assets/img/icon-masks/location.svg)
    mask-size: 0.9em 0.9em

  &.lock img
    mask-image: url(~@/assets/img/icon-masks/lock.svg)
    mask-size: 0.85em 0.85em

  &.projects img
    mask-image: url(~@/assets/img/icon-masks/projects.svg)
    mask-size: 0.9em 0.9em

  &.projects-circle img
    mask-image: url(~@/assets/img/icon-masks/projects-circle.svg)
    mask-size: 0.9em 0.9em

  &.upload img
    mask-image: url(~@/assets/img/icon-masks/upload.svg)
    mask-size: 0.9em 0.9em

  &.plus
    img
      mask-image: url(~@/assets/img/icon-masks/plus.svg)
    //mask-size: 0.9em 0.9em

    &.plus img
      mask-image: url(~@/assets/img/icon-masks/plus-bold.svg)

  &.mail img
    mask-image: url(~@/assets/img/icon-masks/mail.svg)
    mask-size: 0.9em 0.9em

  &.attemdees img
    mask-image: url(~@/assets/img/icon-masks/attemdees.svg)
    mask-size: 0.9em 0.9em

  &.user-plus img
    mask-image: url(~@/assets/img/icon-masks/user-plus.svg)
    mask-size: 0.8em 0.8em

  &.dots
    img
      mask-image: url(~@/assets/img/icon-masks/dots.svg)
      mask-size: 0.9em 0.9em

    &.v img
      transform: rotate(90deg)

  &.trash img
    mask-image: url(~@/assets/img/icon-masks/trash.svg)
    mask-size: 0.8em 0.8em

  &.label img
    mask-image: url(~@/assets/img/icon-masks/label.svg)
    mask-size: 0.8em 0.8em

  &.checklist img
    mask-image: url(~@/assets/img/icon-masks/checklist.svg)
    mask-size: 0.8em 0.8em

  &.archive img
    mask-image: url(~@/assets/img/icon-masks/archive.svg)
    mask-size: 0.8em 0.8em

  &.leave img
    mask-image: url(~@/assets/img/icon-masks/leave.svg)
    mask-size: 0.8em 0.8em

  &.search img
    mask-image: url(~@/assets/img/icon-masks/search.svg)
    mask-size: 0.9em 0.9em

  &.list img
    mask-image: url(~@/assets/img/icon-masks/list.svg)
    mask-size: 0.8em 0.8em

  &.message-pop img
    mask-image: url(~@/assets/img/icon-masks/message-pop.svg)
    mask-size: 0.85em 0.85em

  &.message-pop-circle img
    mask-image: url(~@/assets/img/icon-masks/message-pop-circle.svg)
    mask-size: 0.85em 0.85em

  &.help img
    mask-image: url(~@/assets/img/icon-masks/help.svg)
    mask-size: 0.85em 0.85em

  &.pencil img
    mask-image: url(~@/assets/img/icon-masks/pencil.svg)
    mask-size: 0.85em 0.85em

  &.smile img
    mask-image: url(~@/assets/img/icon-masks/smile.svg)
    mask-size: 0.85em 0.85em

  &.clip img
    mask-image: url(~@/assets/img/icon-masks/clip.svg)
    mask-size: 0.85em 0.85em
  
  &.cross img
    mask-image: url(~@/assets/img/icon-masks/cross.svg)
    mask-size: 0.85em 0.85em
  
  &.documents img
    mask-image: url(~@/assets/img/icon-masks/documents.svg)
    mask-size: 0.85em 0.85em

  &.user-contacts img
    mask-image: url(~@/assets/img/icon-masks/user-contacts.svg)
    mask-size: 0.85em 0.85em

  &.description img
    mask-image: url(~@/assets/img/icon-masks/description.svg)
    mask-size: 0.85em 0.85em

  &.check img
    mask-image: url(~@/assets/img/icon-masks/check.svg)
    mask-size: 0.85em 0.85em