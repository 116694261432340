
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.toggle
  cursor: pointer
  display: inline-block
  background-color: #7f848b
  line-height: 0em
  padding: 0.2em 1em
  border: 0.05em solid #7f848b
  border-radius: 100rem
  position: relative
  height: 1.4em

  &:after
    content: ''
    height: 1.2em
    width: 1.2em
    display: inline-block
    background-color: #ffffff
    border-radius: 100%
    position: absolute
    top: 0
    left: 0
    bottom: 0
    left: 0
    margin: auto
    transition: 0.2s all

  &.active
    background-color: #ff805d
    border-color: #ff805d

    &:after
      right: 0
      left: unset

  input
    display: none
