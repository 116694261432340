
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.avatar
  display: inline-block
  border-radius: 100%
  box-shadow: 0em 0em 0.3em 0em rgba(0,0,0,0.1)
  @include no-select
  background-color: #D1D5DA
  position: relative

  .img-wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-size: cover
    background-position: center
    border-radius: 100%

  span
    display: block
    color: #7F848B
    font-weight: bold
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    font-size: 1.3em

  &.avatar--border-size_small
    border: 0.07em solid $avatar-border-color

  &.avatar--border-size_normal
    border: 2px solid $avatar-border-color

  &.avatar--shape_square
    border-radius: 6px

    .img-wrapper
      border-radius: 6px

  &.avatar--no-shadow
    box-shadow: none

  &.avatar--invited
    background-color: $blue-light-color

.avatar__icon
  position: absolute
  top: 50%
  left: 50%
  transform: translate3d(-50%, -50%, 0)
