
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.search-drop-list
  position: relative
  width: 18em
  min-width: 18em

  &.drop
    &>.header
      .ui.input input
        border-bottom-left-radius: unset
        border-bottom-right-radius: unset
        border-bottom: unset

  &>.header
    display: flex
    cursor: pointer

    .ui.input input
      @include text-ellipsis

    .ui.input.has-suffix input
      padding-right: 3em

    .suffix
      position: absolute
      top: 0.9em
      right: 0.9em
      pointer-events: none

  &>.body
    position: absolute
    z-index: 2
    background-color: #ffffff
    left: 0
    right: 0
    box-shadow: -0.8em 0.95em 1.5em rgba(45, 68, 126, 0.18)
    border: $input-border-style
    border-bottom-right-radius: 0.3rem
    border-bottom-left-radius: 0.3rem

    .filter
      display: flex
      border-bottom: $input-border-style
      align-items: center
      background-color: #ECF1F6

      .ui.svg
        margin: 0em 0em 0em 1em

      input
        padding: 0.7em 1em
        border: unset
        background: unset
        flex: 1

    .list
      max-height: 20em
      
    
    .list .item,
    .select-all
      display: flex
      padding: 0.8em 1em
      overflow: hidden

      cursor: pointer

      &:not(:last-child)
        border-bottom: 0.05em solid #D8D8D8

      .selected
        margin-right: 1em

      .ui.avatar
        margin-right: 1em

      .name
        @include text-ellipsis
        margin: auto auto auto 0em
        font-size: 1.1em
        color: #34335C
        font-family: NunitoSans

      .selected
        @include no-select
        display: flex

        &:after
          content: ""
          padding: 0.7em
          border: 0.05em solid #989898
          border-radius: 100%
          margin: auto 0em
          cursor: pointer
          transition: 0.2s all
          background-position: center
          background-size: 0.7em
          background-repeat: no-repeat

        &.active:after
          background-image: url(~@img/theme-check.svg)
      
    .select-all
      border-top: $input-border-style
      background-color: #F6F8FA