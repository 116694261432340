
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.errors-block
  padding: 0.5em 0em

  .error-message
    color: #FB6C88
    background-image: url(~@/assets/img/o-error.svg)
    background-position: top left
    background-repeat: no-repeat
    background-size: 1em
    padding-left: 1.5em
    font-family: 'NunitoSans'
    line-height: 1.05em

    &:not(:last-child)
      margin-bottom: 0.5em
  
  &.empty-hide:not(.active)
    display: none
