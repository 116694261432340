
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.drop-area
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(255,255,255, 0.95)
  z-index: 6
  border: 0.1em dashed #7393AB
  display: flex

  &>.body
    margin: auto
    display: flex
    flex-direction: column

    .img
      background-image: url("~@img/shareFilesProejctInfo.svg")
      background-position: center
      background-size: contain
      background-repeat: no-repeat
      display: inline-block
      padding: 2em
      margin-bottom: 2em

    .description
      color: $primary-color
      font-size: 1.2em
      text-align: center