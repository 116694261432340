
          @use "sass:math";
          @use "sass:color";
          @use "sass:list";
          @use "sass:selector";
          @use "@/assets/sass/config.sass" as *;
        

.checklist-item-stub {
  box-sizing: border-box;
  color: $primary-color;
  border: 0.07em solid #c3cfd9;
  border-radius: 0.3em;
  background-color: #fff;

  min-height: 2.1em;

  // the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
  // TODO: remove the double selector when pure-marking rules are removed
  &.checklist-item-stub {
    padding: 0.35em calc(1px + 1em) 0.35em calc(1px + 2.3em);
  }
}

.checklist-item-stub__text {
  font-size: 0.9em;
}
