
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
@mixin btnImg
  text-align: left
  padding-left: 3.2em

  background-repeat: no-repeat
  background-position-y: center
  background-position-x: 1em
  background-size: 1.35em
  transition: 0.2s

@mixin define-type($main-color, $main-color-hover, $auxiliary-color: #fff)
  background-color: $main-color
  color: $auxiliary-color

  // as for .ui.button-c[disabled]
  &[disabled],
  &:disabled
    opacity: 0.6

  &:hover
    background-color: $main-color-hover

  &.o
    background-color: $auxiliary-color
    border-color: $main-color
    color: $main-color

    &:hover
      border-color: $main-color-hover
      color: $main-color-hover

.ui.button
  border: 2*$input-borders solid transparent
  position: relative

  padding: 0.6em 1.8em
  font-weight: bold
  font-size: 0.9em
  border-radius: 0.2rem
  white-space: nowrap

  .svg-cc
    margin-right: 1em
    font-size: 0.9em

  .ref
    position: absolute
    top: 0
    left: 0

  // size modifiers
  &.large
    // TODO: remove the !important property after refactoring the component styles
    padding: 0.8em 1em !important
    font-size: 1em

  &.small
    font-size: 0.8em
    padding: 0.5em 1em
    font-weight: 500
    border-width: $input-borders
  // END: size modifiers

  &.circle
    border-radius: 10em
    padding: unset
    display: flex
    align-items: center
    justify-content: center

    height: 2.3em
    width: 2.3em

    .svg-icon
      margin: auto

    .ui.icon
      font-size: 1.2em

    &.with-hover
      &:hover
        box-shadow: 0 0 0 0.25em rgba(#7F848B, 0.2)

    &.inline
      display: inline-flex

  &.back-button
    @include clear-button

    border-radius: 100%
    background: #ffffff
    margin: auto 0em
    padding: 0.7em
    display: flex
    font-size: inherit

    .ui.icon
      font-size: 1.7em

    &.inline
      display: inline-flex

  &:disabled
    opacity: 0.45
    cursor: not-allowed

  // @deprecated modifiers (unused)
  &.secondary,
  &.theme
    background-color: $theme-color
    color: #ffffff

    &:disabled
      opacity: 1
      color: fade-out(#ffffff, 0.5)

    &.o
      background-color: #ffffff
      color: $theme-color
      border-color: $theme-color
  // END: @deprecated modifiers (unused)

  &.primary
    background-color: $primary-color
    color: #ffffff

    &.o
      background-color: #ffffff
      color: $primary-color
      border-color: $primary-color

  &.danger
    background-color: $error-color
    color: #ffffff

    &.link
      border-color: transparent
      color: $error-color
      background-color: #ffffff

    &.o
      background-color: #ffffff
      color: $error-color
      padding: 0.7em 1.8em
      border-color: $error-color

  &.archive
    background-size: 1em

    &.o
      @include btnImg
      background-image: url(~@img/archive-dark.svg)

  &.save
    @include btnImg
    background-image: url(~@ui-icons/circle-check.svg)

  &.shared
    @include btnImg
    background-image: url(~@img/spin-load.svg)

  &.email
    @include btnImg
    background-image: url(~@ui-icons/email.svg)

  &.convert
    @include btnImg
    padding-left: 3.2em !important
    background-image: url(~@img/convert-white.svg)

  &.plus
    @include btnImg
    background-image: url(~@ui-icons/cross.svg)
    background-size: 1em
    background-position-x: 1.2em

  &.cloud-upload
    @include btnImg
    background-image: url(~@img/cloud-upload-white.svg)
    background-size: 1.3em
    background-position-x: 1.2em

  &.bali-hai
    background-color: $bali-hai-color
    color: #ffffff

    &.o
      border-color: $bali-hai-color
      background-color: #ffffff
      color: $bali-hai-color

  &.slate
    background-color: #7F848B
    color: #ffffff

    &.with-hover
      transition: background 0.2s linear, box-shadow 0.2s linear
      &:hover
        background-color: #A8ABB0

  &.blue-violet
    color: #ffffff
    background-color: #5552BB

  &.grey
    background-color: #e9eff4
    color: #7f848b

    &:hover
      background-color: #CED8E0

    &.plus
      background-image: url(~@img/plus-dark-grey.svg)
      background-size: 0.8em
      background-position-x: 1.2em
      padding-left: 3em

    &.light
      color: #ffffff
      background-color: #cbd2d8

    &.o
      background-color: #ffffff
      border: 1px solid $base-border-color

  &.successed-grey
    background-color: #e9eff4
    color: #7f848b
    font-size: .9em

    &:hover
      color: $green-color

    &.plus
      background-image: url(~@img/plus-dark-grey.svg)
      background-size: 0.8em
      background-position-x: 1.2em
      padding-left: 3em

    &.light
      color: #ffffff
      background-color: #cbd2d8

    &.o
      background-color: #ffffff
      border: 1px solid $base-border-color

  &.green,
  &.success
    @include define-type($control-green-color, $control-green-color-hover)

  &.shamrock
    @include define-type($color-shamrock, $color-shamrock)

  &.info-light
    @include define-type($info-light-color, $info-light-color, $primary-color)

  &.grey-transparent
    @include define-type(transparent, $lavander-color, $primary-color)

  &.transparent
    background: unset
    border: unset
    font-weight: normal

  &.full-width
    width: 100%

  &.integration-promo,
  &.integration-promo-underline
    @include define-type(#E9EFF4, #D4DDE4, $primary-color)
    border: none
    padding: 0.7em 1em

  &.integration-promo-underline
    text-decoration: underline
