
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.calendar-picker
  position: absolute
  background-color: #ffffff
  z-index: 2
  box-shadow: $modal-shadow !important
  border-radius: 0.2rem
  color: $primary-color
  overflow: hidden
  height: 22em

  .calendar-month-body
    padding: 1em 1em 1em 0.7em

    .calendar-week-header
      padding: 0.4em 0.5em

    .calendar-week-number
      padding: 0.5em 0.3em 0.5em 0
      vertical-align: middle
      font-size: 0.7em
      font-weight: bold
      font-style: italic
      opacity: 0.6

    .calendar-date
      padding: 0.4em 0.5em
      cursor: pointer
      text-align: center
      transition: 0.2s all

      &.grey
        opacity: 0.5

      &.today
        color: $theme-color

      &.selected
        background-color: $bali-hai-color
        color: #ffffff
        border-radius: 0.2rem

      &:hover
        background-color: fade-out($bali-hai-color, 0.7)

  .calendar-month-header
    display: flex
    align-items: center
    padding: 1.5em 1.2em 1em 1.2em

    .title
      flex: 1
      text-align: center
      font-weight: bold
      font-size: 1.2em

    .btn-arrow
      padding: 0.3em
      cursor: pointer