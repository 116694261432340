
          @use "sass:math";
          @use "sass:color";
          @use "sass:list";
          @use "sass:selector";
          @use "@/assets/sass/config.sass" as *;
        

.folder-preview {
  font-size: inherit;
  width: 2em; // 30px
  height: 2em; // 30px
}

.folder-preview--size_large {
  width: 6em; // 90px
  height: 6em; // 90px
}
