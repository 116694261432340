
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.date-picker
  //position: relative

  .input-box
    display: flex
    
    &,
    input
      cursor: pointer
    
    .icon-calendar
      width: 3em
      background-color: rgba(233, 239, 244, 0.4)
      border: 0.05em solid #e9eff4
      background-image: url(./icon-calendar.svg)
      background-size: 1em
      background-position: center
      background-repeat: no-repeat

  .modal-picker
    position: absolute
    //top: 100%
    //left: 0
    z-index: 1
    min-width: 18em
    max-width: 18em
    box-shadow: 0em 0.2em 1em 0.1em rgba(0,0,0,0.15)
    border-radius: 0.2rem

    &>.header
      border-radius: 0.2rem 0.2rem 0rem 0rem
      display: flex
      background-color: lighten(#34335C, 10%)
      color: #ffffff

      .btn
        border: unset
        background: unset
        padding: 0.6em 1.2em
        cursor: pointer
        font-weight: bold
        transition: 0.2s all
        background-position: center
        background-size: 0.8em
        background-repeat: no-repeat

        &:hover
          background-color: rgba(255,255,255,0.2)

        &.prev
          background-image: url(./arrow-down.svg)
        
        &.next
          background-image: url(./arrow-up.svg)
      
      .select-year
        width: 100%
        text-align: center

        .year
          cursor: pointer
          margin-left: 1em
          transition: 0.2s opacity

          &:hover
            opacity: 0.8

        .selected
          padding: 0.6em 1em
    
    &>.body
      display: flex
      position: relative

      border-radius: 0rem 0rem 0.2rem 0.2rem
      background-color: #ffffff

      .days
        text-align: center
        width: 100%
        color: #7f848b
 
        .day
          padding: 0.5em 0.5em
          cursor: pointer
          transition: 0.2s all
          color: #333333

          &:hover
            color: $theme-color

          &.active
            //background-color: $theme-color
            color: $theme-color
            font-weight: bold

  .years
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #ffffff

    .scroll
      height: 100%

    .year
      display: inline-block
      width: 25%
      padding: 0.5em 0em
      text-align: center
      border: 0.05em solid fade-out(#e9eff4, 0.7)
      color: #7f848b
      transition: 0.2s all
      cursor: pointer

      &:hover
        color: $theme-color

      &.active
        color: $theme-color
        font-weight: bold

