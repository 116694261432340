
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.button-c
  display: inline-flex
  align-items: center
  border: 0.07em solid transparent
  padding: 0.3em 0.7em
  color: $primary-color

  border-radius: 0.3em
  cursor: pointer
  justify-content: center
  transition: 0.2s all
  line-height: 1.4em

  .ui.svg
    margin-right: 0.3em
    pointer-events: none

    &.small
      font-size: 0.7em
      margin-right: 0.7em

    &.svg--size_small
      margin-right: 8px

  &.only-svg
    .ui.svg
      margin-right: unset

  &[disabled]
    opacity: 0.60

  @import './skins.sass'