
          @use "sass:math";
          @use "sass:color";
          @use "sass:list";
          @use "sass:selector";
          @use "@/assets/sass/config.sass" as *;
        

.em-tracker {
  flex-shrink: 0;
  flex-grow: 0;
  width: 1em;
  height: 1em;
  position: absolute;
  z-index: -1;
}
