
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.counter
  display: inline-block
  font-size: 0.75em
  min-width: 2.5em
  text-align: center
  padding: 0.1em 0em
  border-radius: 1rem
  background-color: $theme-color
  color: #ffffff
  max-height: 1.6em
  min-height: 1.6em

  &.grey
    background-color: #D1D7DD

  &.circle
    min-width: 1.5em
    padding: 0.1em 0.5em
  
  &.oval
    padding: 0.1em 1em

  &.dark-blue
    background-color: #5552BB

  &.blue
    background-color: $theme-blue-color