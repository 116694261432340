
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
@mixin color-setter($bg-color, $border-color, $icon-color, $round:0em)
  &
    .input-checkbox
      border-color: $border-color
      background-color: $bg-color
      border-radius: $round

      .ui.svg
        @include svg-color($icon-color)

.ui.checkbox
  display: flex
  color: #686868

  &:not(.active)
    .input-checkbox
      .ui.svg
        opacity: 0

      &:hover .ui.svg
        opacity: .3

  &.active .input-checkbox:hover .ui.svg
    opacity: .5

  .input-checkbox
    margin: auto 0em
    margin-right: 0.8em

    cursor: pointer
    display: flex
    border: 0.05em solid #91A1C6
    border-radius: 0.1em
    width: 1.1em
    height: 1.1em
    cursor: pointer
    position: relative
    transition: 0.2s all
    flex-shrink: 0

    .ui.svg
      font-size: 0.6em
      margin: auto
      transition: .2s opacity

      p, path
        fill: $theme-color

    input
      position: absolute
      opacity: 0
      width: 100%
      height: 100%
      display: block
      cursor: pointer

  label
    color: inherit

  &.large
    .input-checkbox
      width: 1.3em
      height: 1.3em

  &.rounded
    .input-checkbox
      border-radius: 3px

  &.primary
    .input-checkbox
      border-color: #BCCCD5
      background-color: #ffffff

    .input-checkbox
      .ui.svg
        p, path
          fill: $primary-color

  &.grey-white,
  &.grey
    .input-checkbox
      border-color: #B2C0CD

    &.active
      .input-checkbox
        border-color: #E9EFF4
        background-color: #E9EFF4

    .ui.svg
      p, path
        fill: #7F848B

  &.grey-white
    &.disabled:not(.active)
      .input-checkbox
        background-color: white

  &.green
    .input-checkbox
      background-color: #ffffff
      border-color: #CBD2D8

    .ui.svg
      p, path
        fill: $green-color

  &.theme-blue
    .input-checkbox
      background-color: #ffffff

    .ui.svg
      p, path
        fill: $theme-blue-color

  &.green
    .input-checkbox
      border-color: $base-border-color

    .ui.svg
      p, path
        fill: $green-color

  &.circle-blue
    .input-checkbox
      border-radius: 100%
      border-color: #7394AB
      background-color: #ffffff

    &.active
      .input-checkbox
        background-color: #7394AB

      .ui.svg
        p, path
          fill: #ffffff

    .ui.svg
      p, path
        fill: #7394AB

  &.circle-theme-o
    .input-checkbox
      border-radius: 100%
      border-color: #C9D2D9
      background-color: #ffffff

    &.active
      .input-checkbox
        // background-color: #7394AB

    .ui.svg
      p, path
        fill: $theme-color

  &.auto
    &:not(.has-label)
      .input-checkbox
        margin-right: 0em

  &.disabled
    pointer-events: none

    .input-checkbox
      background-color: $control-background-color-disabled
      border-color: $control-border-color-disabled

    label
      color: #C7C9CD

    .ui.svg
      p, path
        fill: currentColor
        opacity: 0.4

.ui.checkbox
  &.circle-theme-blue-o
    @include color-setter(#ffffff, #CBD2D8, $theme-blue-color, 10em)

  &.oslo-gray
    @include color-setter(#ffffff , #CBD2D8, #7F848B, 0em)

    &.active
      @include color-setter(#E9EFF4 , #CBD2D8, #7F848B, 0em)

  &.small
    font-size: 0.9em

    .input-checkbox
      width: 1em
      height: 1em
      margin-top: 0.15em
      margin-right: 0.5em

  &.error
    @include color-setter($white-color, $error-color, $error-color, 0.1em)
