.planning-data
  flex: 1
  overflow-y: scroll
  position: relative

  @import './greed'

  // Firefox scrollbar styles
  scrollbar-color: #9BA3AA #ffffff !important
  scrollbar-width: thin !important

  // Chrome scrollbar styles
  &::-webkit-scrollbar
    width: 1em
    height: 1em

  &::-webkit-scrollbar-thumb
    background-color: #9BA3AA
    box-shadow: inset 0em 0em 10em 0em #9BA3AA
    border-radius: 10em

  .planning-data-inner
    position: relative
    height: auto

  .planning-data-inner>*:not(.greed)
    position: relative

  .current-time-line
    position: absolute
    height: 10em
    z-index: 2
    top: 0
    display: flex
    width: 0.4em
    padding-top: 1.5em
    pointer-events: none

    flex-direction: column
    align-items: center

    .circle
      height: 0.4em
      width: 0.4em
      background-color: #FD7850
      border-radius: 100%

    .current-line
      flex: 1
      background-color: #FD7850
      width: 0.07em

  /*
   * LinePlanning
   */
  .group-planning
    min-height: $min-height-item
    position: relative
    background-color: #F2F5F8
    border-bottom: 0.07em solid #E8E8E8
    width: 100%

  /*
   * LinePlanning
   */
  .line-planning
    position: relative
    min-height: $min-height-item
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0.2em 0em
    border-bottom: 0.07em solid #E8E8E8

    .item-hover
      position: absolute
      top: 0em
      bottom: 0em
      background-color: #EDEEF0
      pointer-events: none
      // margin: 0.2em
      // transition: 0.2s all
      border: 0.07em solid #E8E8E8

    .abs-line
      min-height: $min-height-item - 0.8em
      position: relative
      display: flex
      align-items: center

      .line-item
        position: absolute

    .line-item
      z-index: 1
      color: #ffffff
      border-radius: 0.3em
      // cursor: pointer
      cursor: grab
      margin: 0.2em 0em
      white-space: nowrap
      position: relative
      display: flex
      align-items: center
      transition: 0.2s all

      &.resize,
      &.dragging
        opacity: 0.8
        z-index: 1

      &.dragging
        cursor: grabbing
        transition: none // TODO: enable for `Implementation of item sticking by grid

      &.search
        z-index: 25

      .drag-left,
      .drag-right
        width: 0.8em // for proper text overlap
        display: flex
        flex-shrink: 0
        justify-content: center
        padding: 0.5em 0
        transition: 0.2s all
        cursor: col-resize

        &:not(:hover)
          opacity: 0.7

      .name
        max-width: calc(100% - 1.6em) // for proper text overlap
        flex-grow: 1 // for proper text overlap
        display: flex
        position: relative

        span
          left: 0.45em
          position: sticky
          @include text-ellipsis
