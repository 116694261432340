
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.users-row-avatars
  display: flex
  align-items: center

  .item
    &:not(:last-child)
      margin-right: 0.5em

  // &.simple
  //   .item
  //     padding: unset
  //     margin: auto
      // display: flex

.users-row-avatars__list
  display: flex
  align-items: center
  flex-shrink: 1
  overflow: hidden

  &:not(:last-child):not(.users-row-avatars__list--empty)
    margin-right: 0.5em

.users-row-avatars--is_dense
  .item
    &:not(:last-child)
      margin-right: auto

    &:not(:first-child)
      margin-left: -0.5em

.users-row-avatars--full-width
  width: 100%
