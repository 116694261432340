
          @use "sass:math";
          @use "sass:color";
          @use "sass:list";
          @use "sass:selector";
          @use "@/assets/sass/config.sass" as *;
        

.checklist-placeholder {
  display: flex;
  flex-wrap: wrap;

  .checklist-placeholder__menu,
  .checklist-placeholder__image {
    width: 1em;
    height: 1em;
    font-size: 1.3em;
    margin: auto 0.3em auto 0em;
  }
  .checklist-placeholder__menu {
    height: 2.3em;
    width: 2.3em;
    font-size: 0.9em;
    border-radius: 10em;
    margin-left: auto;
    margin-right: 0;
  }
  .checklist-placeholder__text {
    flex: 0 1 30%;
    margin-top: 0px;

    .vue-content-placeholders-text__line {
      border-radius: 3px;
      height: 30px;
      margin-bottom: 0;
    }
  }
  .checklist-placeholder__button {
    flex: 0 1 100%;
    margin-top: 1em;

    .vue-content-placeholders-text__line {
      border-radius: 0.2rem;
      height: 2.6em;
      margin-bottom: 0;
      width: 130px;
      font-size: 0.8em;
      padding: 0.5em 1em;
    }
  }
}
.checklist-placeholder--compact {
  padding: 1.5em 2em 0em 2em;

  .checklist-placeholder__text {
    flex: 0 1 70%;
  }
}
