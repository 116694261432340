
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.date-input
  width: 100%

  .header
    border: 0.05rem solid #B2C0CC

    input
      padding-right: 0

      &:disabled
        color: #34335C

.ui.date-input--has_error
  .header
    border-color: $error-color
