
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.find-and-select-user
  .search
    display: flex

    .box-selected
      width: 25em
      min-width: 25em
      max-width: 25em
      max-height: 8em

      border: 0.05em solid #e9eff4
      padding: 0em 0.5em 0.5em 0.5em
      border-radius: 0.3rem
      font-family: 'NunitoSans'
      color: #34335C
      display: flex
      flex-wrap: wrap
      cursor: text

      .search-icon
        height: 2em
        width: 2em
        margin: auto
        margin-top: 0.5em
        background-image: url(~@img/find-grey.svg)
        background-repeat: no-repeat
        background-size: 1em
        background-position: center

      .item
        color: #ffffff
        display: inline-block
        margin-right: 0.5em
        height: 2em
        cursor: pointer
        margin: 0.5em 0.5em 0em 0em

        .c-item
          height: 2em
          background-color: #FF805D
          border-radius: 0.3rem
          display: flex

        .avatar
          height: 2em
          width: 2em
          background-size: cover
          background-repeat: no-repeat
          background-position: center
          display: inline-block

        .name
          display: inline-block
          padding: 0em 0.5em
          padding-right: unset
          margin: auto 0em

        .cross
          border: unset
          background: unset
          background-image: url(~@img/white-cross.svg)
          background-size: contain
          background-repeat: no-repeat
          background-position: center
          width: 0.6em
          height: 0.6em
          margin: auto 0.5em

    .search-input
      border: unset
      background: unset
      min-width: 5em
      flex: 1
      height: 2em
      padding-left: 0.5em
      margin-top: 0.5em

    .submit
      margin-left: 1em
      height: 3.5em

      &:disabled
        opacity: 0.7
        cursor: not-allowed

  &>.list
    margin-top: 1em
    width: 25em
    
    .item
      display: flex
      padding: 0.8em 0em

      cursor: pointer

      &:not(:last-child)
        border-bottom: 0.05em solid #D8D8D8

      .selected
        margin-right: 1em

      .name
        margin: auto auto auto 1em
        font-size: 1.1em
        color: #34335C
        font-family: NunitoSans

      .selected
        @include no-select
        display: flex

        &:after
          content: ""
          padding: 0.7em
          border: 0.05em solid #989898
          border-radius: 100%
          margin: auto 0em
          cursor: pointer
          transition: 0.2s all
          background-position: center
          background-size: 0.7em
          background-repeat: no-repeat

        &.active:after
          background-image: url(~@img/theme-check.svg)
      
