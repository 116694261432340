
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
// TODO: remove the double selector when pure-marking rules are removed

.text-button
  display: inline-flex
  background: transparent
  border: none
  align-items: center
  transition: all 0.3s

  &:disabled
    opacity: 0.6

  .text-button__prefix,
  .text-button__text
    display: flex

  .text-button__prefix
    margin-right: 0.6em
    margin-right: 1ch
    font-size: 0.9em

.text-button--kind_info
  color: $info-color

.text-button--kind_primary
  color: $primary-color

.text-button--kind_success
  color: $green-color

.text-button--kind_link
  color: $link-color


.text-button--size_larger
  font-size: 1.1em


.text-button--weight_bold
  font-weight: bold

// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
// TODO: remove the double selector when pure-marking rules are removed
.text-button.text-button--action-effect
  padding: 0.25em 0.5em
  border-radius: $base-border-radius

  &:hover
    background-color: $icon-button-background-color-hover

.text-button--inline
  display: inline

  .text-button__prefix,
  .text-button__text
    display: inline