
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking package)
// TODO: remove the double selector when pure-marking rules are removed
.ui-tag.ui-tag
  padding: 0 0.3em

.ui-tag
  display: inline-block
  color: $tag-text-color
  background-color: #859DB4
  border-radius: $tag-border-radius
  line-height: 1.5em

.ui-tag--kind_success
  background-color: $tag-background-color-success

.ui-tag--kind_error
  background-color: $tag-background-color-error
