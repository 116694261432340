
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.location-input
  display: flex
  position: relative
  width: 100%
  height: 2.7em
  min-width: 0

  &.drop
    &>.header
      border-radius: 0.3rem 0.3rem 0rem 0rem
      border-bottom: none

  &.error
    &>.header
      border-color: $error-color
      .placeholder
        color: $error-color

  &>.header
    background-color: #ffffff
    display: flex
    flex: 1
    align-items: center
    border: $input-border-style
    padding: 0.7em 1em
    border-radius: 0.3rem
    font-family: 'NunitoSans'
    font-size: 0.9em
    color: #34335C
    cursor: pointer
    overflow: hidden

    .placeholder
      flex: 1
      @include text-ellipsis
      &.empty
        color: $placeholder-color

  &>.body
    background: #ffffff
    position: absolute
    z-index: 2
    top: 100%
    left: 0
    right: 0
    border: $input-border-style
    border-radius: 0em 0em 0.3rem 0.3rem
    box-shadow: 0em 0em 0.5em 0em fade-out(#000000, 0.9)
    max-height: 35em

    .find
      background-color: #ECF1F6
      padding: 0em 1em
      display: flex
      align-items: center

      input
        border: unset
        background: unset
        padding: 0.7em
        color: #7A93A8
        flex: 1

    .loaded
      &.loaded-active
        height: 10em
        opacity: 1

        &:after,
        &:before
          position: absolute
          content: ""
          left: 0
          right: 0
          top: 0
          bottom: 0

        &:before
          z-index: 20
          background-image: url(~@img/woub-load.gif)
          background-position: center
          background-repeat: no-repeat
          background-size: 7em

        &:after
          background-color: #ffffff
          opacity: 1
          z-index: 10

    .no-search
      color: #7F848B
      padding: 2em 2.5em

    .list
      max-height: 10em

      .item
        padding: 0.5em 1em
        font-size: 0.9em
        color: $primary-color
        cursor: pointer
        transition: 0.2s all
        @include text-ellipsis
        
        &:hover
          opacity: 0.7
