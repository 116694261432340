
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui-quill.quill-editor
  all: unset
  width: 100%
  flex: 1
  display: flex
  flex-direction: column
  border: $input-border-style
  border-color: #D5DCE1
  border-radius: 0.3rem
  background-color: #ffffff

  .ql-tooltip
    border: none
    box-shadow: 0em 0em 0.4em 0.15em fade-out(#000000, 0.9)
    border-radius: 0.2rem
    transform: initial

    &:before
      content: "Visit URL:"
      content: var(--ql-tooltip-label-url, "Visit URL:")

    &[data-mode=link]:before
      content: "Enter link:"
      content: var(--ql-tooltip-label-url-modify, "Enter link:")

    a.ql-action:after
      content: "Edit"
      content: var(--ql-tooltip-label-action, "Edit")

    a.ql-remove:before
      content: "Remove"
      content: var(--ql-tooltip-label-remove, "Remove")

    &.ql-editing
      a.ql-action:after
        content: "Save"
        content: var(--ql-tooltip-label-action-editing, "Save")

  .ql-toolbar
    all: unset
    padding: 0.3em 0.5em
    flex: 1
    border-top: $input-border-style
    border-color: #D5DCE1
    order: 2

    .ql-formats
      margin-right: 0.8em

    button
      width: unset
      height: unset
      padding: 0.3em

      svg
        font-size: 1em
        width: 1em
        height: 1em

  .ql-container
    border: unset
    order: 1

    .ql-editor
      color: #34335C
      font-family: 'NunitoSans'
      padding: 0.7em 1em
      min-height: 5em
      word-break: break-all

  &.grey
    background-color: #F3F7FC

  &.comment
    background-color: #F1F7FD
    border: none

    .ql-editor.ql-blank:before
      font-style: normal
  
  &.slim
    .ql-container .ql-editor
      min-height: 3.5em

  &.hide-toolbar
    .ql-toolbar
      display: none

  &.inline
    border: none
    background-color: transparent

    .ql-editor
      padding: 0
      min-height: auto
