
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.warning-block
  padding: 0.5em 0em

  .item-warning
    color: $theme-color
    font-family: 'NunitoSans'
    display: flex

    .ui.svg
      margin-top: 0.1em
      // font-size: 0.9em

    .message
      flex: 1
      padding-left: 0.5em
      line-height: 1.05em

    &:not(:last-child)
      margin-bottom: 0.5em
