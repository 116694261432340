.media.item
  position: relative

/*
 * Styles form block view media item
 */ 
.media.item.block
  $item-radius: 0.3rem
  padding: 0em 0em 1em 0.8em

  &>.body
    position: relative

    &:hover
      .controls
        opacity: 1

    .controls
      position: absolute
      top: 0
      left: 0
      right: 1.4em
      bottom: 0
      background-color: rgba(255,255,255,0.4)
      border-radius: 0.3em
      opacity: 0
      transition: 0.2s opacity
      cursor: pointer

      .ui.checkbox
        position: absolute
        top: 0.3em
        right: 0.3em

        .input-checkbox
          margin: unset

  &>.footer
    overflow: hidden
    width: 6em
    position: relative

    .progress
      background-color: $theme-color
      position: absolute
      left: 0
      height: 0.3em
      border-radius: 10em

    .name
      display: flex
      justify-content: center
      padding-top: 0.3em

      span
        @include text-ellipsis
        color: $primary-color
        font-size: 0.9em
        margin: auto 0em

    .time
      @include text-ellipsis
      text-align: center
      color: #7B93A9
      font-size: 0.65em
      padding-top: 0.3em
  
  // Style from item - folder
  &.folder
    &.virtual
      &>.body .ui.checkbox
        display: none

      &.name-column .check .ui.checkbox
        display: none
      
      &>.footer .name span
        white-space: pre-wrap
        text-align: center

    &>.body
      .preview
        border-radius: $item-radius
        display: flex
        width: 6em
        height: 6em

        img
          margin: auto

  // Style from item - file
  &.file
    &>.body
      .preview
        border-radius: $item-radius
        display: flex

        img
          width: 6em
          height: 6em
          border-radius: $item-radius
          cursor: pointer
          object-fit: contain
          object-position: center
        
        .atachment
          background-size: 5em
          width: 6em
          height: 6em

  &.selected
    &>.body .controls
      opacity: 1

/*
 * Styles form line view media item
 */
.media.item.line
  overflow: hidden
  display: flex
  width: 100%
  padding: 0.5em 0em
  transition: 0.2s background
  cursor: pointer

  &:not(:last-child)
    border-bottom: 0.07em solid #CBD2D8

  &:hover,
  &.selected
    background-color: #EEF2F6

  .progress
    background-color: $theme-color
    position: absolute
    left: 0
    bottom: 0
    height: 2px
    border-radius: 10em
  
  .check
    width: 3em
    display: flex
    padding-left: 1em
    transition: 0.2s opacity

    .ui.checkbox
      margin: auto 0em
      font-size: 0.9em

      .input-checkbox
        margin: unset

  .name
    flex: 1
    overflow: hidden
    display: flex

    span
      @include text-ellipsis
      color: $primary-color
      font-size: 0.9em
      margin: auto 0em

  .time
    width: 7em
    display: flex

  .time,
  .size
    padding-left: .5em

    span
      color: #7B93A9
      font-size: 0.8em
      margin: auto 0em
      white-space: nowrap
  
  .size
    width: 4em
    display: flex

  .actions
    width: 3em
    display: flex
    padding-right: 1em
    transition: 0.2s opacity

    .ui.svg
      margin: auto
      font-size: 1.2em
      pointer-events: none

  &:not(:hover)
    &:not(.selected)
      .check
        opacity: 0
  
  &:not(:hover),
  &.selected,
  &.block-context
    .actions
      opacity: 0
      pointer-events: none

  // Style from item - folder
  &.folder
    &.virtual
      &>.check
        .ui.checkbox
          display: none
      
      &>.actions
        opacity: 0
        pointer-events: none

    .preview
      width: 3em
      display: flex

      img
        margin: auto 0em

  // Style from item - file
  &.file
    .preview
      display: flex
      width: 3em

      img
        margin: auto 0em
        width: 2em
        height: 2em
        border-radius: 0.3rem
        cursor: pointer
        object-fit: contain
        object-position: center

      .atachment
        margin: auto 0em
        background-size: 2em
        width: 2em
        height: 2em

.icon-remote
  flex-shrink: 0
  margin-right: 0.4em
  margin-top: 0.1em
  font-size: 1em

.icon-remote-list
  flex-shrink: 0
  margin-right: 0.4em
  margin-top: 0.4em
  font-size: 1em
