
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
// TODO: move nested selectors from the namespace (.ui-link) when pure-marking rules are removed

.ui-link
  display: inline-flex
  align-items: center
  color: $link-color

  .ui-link__prepend
    display: flex
    align-items: center
    font-size: 1.66em
    margin-right: 0.4em

  .ui-link__text
    display: inline
    padding-bottom: 0.1em
    text-decoration: underline

.ui-link--kind_info
  color: $link-color-info

.ui-link--kind_light
  color: $white-color

.ui-link--kind_dark
  color: $primary-color

.ui-link--underline_text-line
  .ui-link__text
    text-decoration: none
    border-bottom: 0.05em solid currentColor

.ui-link--size_small
  font-size: 0.8em
