
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
// TODO: remove the double selector when pure-marking rules are removed
.edit-form-field.edit-form-field
  &--edit
    padding: 0.5em
    border-radius: 0.5em
    background-color: #ECF1F6
    box-shadow: 0em 0.4em 0.3em 0em fade-out(#000000, 0.95)

.edit-form-field
  position: relative
  z-index: 0

// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
// TODO: remove the double selector when pure-marking rules are removed
.edit-form-field__controls.edit-form-field__controls
  margin-top: 0.5em

.edit-form-field__controls
  display: flex

  .ui.button:not(:last-child)
    margin-right: 1em
