
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.input-c
  width: 100%
  position: relative
  display: flex
  flex-direction: column

  &>.header
    display: flex
    background-color: #ffffff
    border-radius: 0.3rem
    border: 1px solid $input-border-color
    // align-items: center
    overflow: hidden

    input
      border: unset
      background: unset
      color: #34335C
      font-family: 'NunitoSans'
      font-size: 0.9em
      padding: 0.7em 1em
      flex: 1
      min-width: 0

  &>.header
    .before-icon
      border-right: $input-border-style
      display: flex
      align-items: center
      justify-content: center
      width: 2.5em
      background-color: #F2F5F8

    .after-icon
      display: flex
      align-items: center
      justify-content: center
      width: 2.5em

      &.small
        .ui.svg
          font-size: 0.85em

  &.no-round-bottom
    &>.header
      border-bottom-left-radius: unset
      border-bottom-right-radius: unset

.ui.input-c--has_error
  > .header
    border-color: $error-color

    input
      color: $error-color
