.add-file-zone
  background-color: fade-out(#ffffff, 0.05)
  height: auto
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1

  padding: 1em
  display: flex
  border: 0.1em dashed #859DB3
  margin: 0em 1em 1em 1em

  &.whithout-opacity
    background-color: transparent
    height: 25em

  .info
    text-align: center
    margin: auto

    .img
      display: inline-block
      margin-bottom: 2em
      position: relative

      .ui.svg.lock
        position: absolute
        top: -0.3em
        right: -0.6em
        font-size: 1.2em

    span
      display: block
      padding: 0.5em
      color: #7A93A8
    
    .title
      font-size: 1.1em
      font-weight: bold
      color: $primary-color

    .description
      color: $primary-color

.add-file-zone__image
  width: 4em
  height: 4em