
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.select-members
  position: relative
  padding: 0em 0em
  margin-bottom: 1.5em

  &>.selected.members
    border: $input-borders solid $input-border-color
    border-radius: 0.3rem
    display: flex
    cursor: pointer
    min-height: 3em
    padding: 0em 1em
    position: relative

    .user-icon
      margin: auto 0.5em
      font-size: 1.5em
      margin-top: 0.4em

    .btn-plus
      position: absolute
      top: 0.4em
      right: 1em

    .placeholder
      flex: 1
      margin: auto 0em
      color: $placeholder-color

    &>.list
      flex: 1
      display: flex
      flex-flow: row wrap
      padding: 0.2em 0em
      max-height: 7.5em
      padding-right: 3em 
      margin-right: -1em

      .item
        display: flex
        margin: 0.2em 0.8em 0.2em 0em
        cursor: pointer
        overflow: hidden
        border: $input-borders solid $primary-color
        border-radius: 0.3em

        max-width: 20em

        .main
          display: flex
          padding: 0em 0.5em
          overflow: hidden

          .ui.svg
            margin: auto
            font-size: 0.6em

          .name
            @include text-ellipsis
            margin: auto 0em
            padding-right: 0.5em
            color: $primary-color
            flex: 1

        .ui.avatar
          border-radius: 0em
          border-right: $input-borders solid $primary-color

          .img-wrapper
            border-radius: unset

  &.droped
    &>.selected.members
      border-bottom-left-radius: unset
      border-bottom-right-radius: unset

  .drop.empty-members
    padding: 1.5em
    
    .title,
    .description
      color: #7F848B

    .title
      font-size: 1.3em

    .description
      padding: 0em 0em 2em 0em

    .ui.button
      margin-right: 0.8em

  .drop
    position: absolute
    top: 0
    left: 0
    z-index: 100
    width: 100%
    will-change: transform

    background-color: #ffffff
    border: $input-borders solid $input-border-color
    border-top: unset
    box-shadow: -0.5em 1em 1em 0.2em rgba(0,0,0,0.1)

    &>.header
      padding: 0.5em 1em

    &>.footer
      padding: 1em
      border-top: $input-borders solid $input-border-color

  .list.members
    max-height: 12em
    padding: 0em

    .item
      display: flex
      padding: 0.5em 1em
      transition: 0.5s opacity
      cursor: pointer
      transition: 0.2s background

      &:hover
        background-color: #F2F5F8

      &:not(:last-child)
        border-bottom: $input-borders solid #B2C0CC
      
      .ui.avatar
        margin-right: 1.5em

      .name
        @include text-ellipsis
        margin: auto 0em
        transition: 0.2s