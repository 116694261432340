
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        

$app-loader-size: 1em !default
$app-loader-size-bigger: 1.1em !default
$app-loader-size-large: 2em !default

.app-loader
  vertical-align: middle
  display: inline-flex
  width: $app-loader-size
  height: $app-loader-size

  img
    width: 100%
    height: 100%
    display: block
    animation-name: spinner
    animation-duration: 0.8s
    animation-iteration-count: infinite
    animation-timing-function: linear

.app-loader--size_bigger
  width: $app-loader-size-bigger
  height: $app-loader-size-bigger

.app-loader--size_large
  width: $app-loader-size-large
  height: $app-loader-size-large
