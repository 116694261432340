
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.find-member
  position: absolute
  top: 100%
  left: 0em
  right: 0em
  background-color: #ffffff
  border: $input-borders solid $input-border-color
  overflow: hidden
  border-radius: 0em 0em 0.3em 0.3em
  border-top: unset

  &.in-border
    top: calc( 100% + 0.07em )
    left: -0.07em
    right: -0.07em
  // .drop.empty-members
  //   padding: 1.5em
    
  //   .title,
  //   .description
  //     color: #7F848B

  //   .title
  //     font-size: 1.3em

  //   .description
  //     padding: 0em 0em 2em 0em

  //   .ui.button
  //     margin-right: 0.8em

  // .drop
  //   position: absolute
  //   left: 1.5em
  //   right: 1.5em
  //   background-color: #ffffff
  //   z-index: 1
  //   border: $input-borders solid $input-border-color
  //   border-top: unset
  //   box-shadow: -0.5em 1em 1em 0.2em rgba(0,0,0,0.1)

  //   &>.header
  //     padding: 0.5em 1em

  &>.footer
    padding: 1em
    border-top: $input-borders solid $input-border-color

  &>.body
    .list.members
      max-height: 12em
      padding: 0em

      .item
        display: flex
        padding: 0.5em 1em
        transition: 0.5s opacity
        cursor: pointer
        transition: 0.2s background

        &:hover
          background-color: #F2F5F8

        &:not(:last-child)
          border-bottom: $input-borders solid #B2C0CC
        
        .ui.avatar
          margin-right: 1.5em

        .name
          @include text-ellipsis
          margin: auto 0em
          transition: 0.2s