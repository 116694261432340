
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.checklist-item-name
  display: flex

.checklist-item-name--checked
  .checklist-item-name__text
    font-style: italic
    text-decoration: line-through
    color: $gray-color

.checklist-item-name__loader
  flex-shrink: 0
  margin: 0.5em 0.2em 0.5em 0em

.checklist-item-name__text
  flex-grow: 1
  // TODO: remove !important declaration when pure-marking rules are removed
  padding: 0.4em 0.5em !important
  font-size: 0.9em
  color: $primary-color
  line-height: 1.5
  background: none
  border: none
  text-align: left
  word-break: break-all

.checklist-item-name__checkbox
  &.ui
    .input-checkbox
      margin: 0.5em 0.2em 0.5em 0em
