@keyframes spinner
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

@keyframes lineLoader
  0%
    transform: translate(-100%, 0)
  50%
    transform: translate(100%, 0)
  100%
    transform: translate(-100%, 0)
