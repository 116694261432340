.breadcrumbs
  padding: 0.3em 0.6em
  display: flex
  flex-wrap: wrap

  .access-mode
    margin: auto 0.1em auto 0.4em
    display: flex

  &>.item
    display: flex

    &:last-child
      .arrow
        display: none
    
    .arrow
      font-size: 0.6em
      margin: auto 0.5em

    span
      margin: auto 0em
      color: $primary-color
      cursor: pointer
      padding: 0.2em 0.4em
      transition: 0.2s
      border-radius: 0.2em
      white-space: nowrap

      &:hover
        background-color: #E9EFF4
