.planning-sidebar-wrap
  min-width: $width-sidebar
  max-width: $width-sidebar
  display: flex
  border-right: 0.07em solid #E8E8E8
  padding-bottom: 1em

  @-moz-document url-prefix()
    &
      padding-bottom: 9px
  
.planning-sidebar
  overflow: auto
  flex: 1

  @include opacity-scroll

  /*
   * GroupSidebarPlanning
   */
  .group-sidebar-planning
    background-color: #F2F5F8
    min-height: $min-height-item
    display: flex
    align-items: center
    padding: 0em 1em 0em 2em
    border-bottom: 0.07em solid #E8E8E8

    &:not(:hover)
      .edit
        display: none

    .name
      align-items: center
      display: flex
      flex: 1
      color: #34335C
      cursor: default

      .text
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        max-width: 13.5em

    .drop,
    .edit
      transition: 0.2s all
      cursor: pointer
      padding: 0.5em
      font-size: 0.8em
      display: flex

    .drop .ui.svg
      transition: 0.2s all

  /*
   * UserSidebarPlanning
   */
  .user-sidebar-planning
    border-bottom: 0.07em solid #E8E8E8

    .line-name
      min-height: $min-height-item
      padding: 0em 1em 0em 2em
      display: flex
      align-items: center

      .name
        @include text-ellipsis
        padding-left: 1em
        color: #34335C
        cursor: default
