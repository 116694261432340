
// ---------------------------------------------------------------------
// Roboto
// ---------------------------------------------------------------------

@font-face
  font-family: "Roboto"
  src: url("~@fonts/Roboto/Roboto-Regular.ttf") format("truetype"), url('~@fonts/Roboto/Roboto-Regular.woff2') format('woff2'), url('~@fonts/Roboto/Roboto-Regular.woff') format('woff')
 
@font-face
  font-family: "Roboto"
  src: url("~@fonts/Roboto/Roboto-Bold.ttf") format("truetype"), url("~@fonts/Roboto/Roboto-Regular.ttf") format("truetype"), url('~@fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('~@fonts/Roboto/Roboto-Bold.woff') format('woff')
  font-weight: bold

@font-face
  font-family: "Roboto"
  src: url("~@fonts/Roboto/Roboto-Light.ttf") format("truetype"), url('~@fonts/Roboto/Roboto-Light.woff2') format('woff2'), url('~@fonts/Roboto/Roboto-Light.woff') format('woff')
  font-weight: lighter

@font-face
  font-family: "Roboto"
  src: url("~@fonts/Roboto/Roboto-Medium.ttf") format("truetype"), url('~@fonts/Roboto/Roboto-Medium.woff2') format('woff2'), url('~@fonts/Roboto/Roboto-Medium.woff') format('woff')
  font-weight: 500


// ---------------------------------------------------------------------
// NunitoSans
// ---------------------------------------------------------------------

@font-face
  font-family: "NunitoSans"
  src: url("~@fonts/NunitoSans/NunitoSans-Regular.ttf") format("truetype"), url('~@fonts/NunitoSans/NunitoSans-Regular.woff2') format('woff2'), url('~@fonts/NunitoSans/NunitoSans-Regular.woff') format('woff')

@font-face
  font-family: "NunitoSans"
  src: url("~@fonts/NunitoSans/NunitoSans-Regular.ttf") format("truetype"), url('~@fonts/NunitoSans/NunitoSans-Regular.woff2') format('woff2'), url('~@fonts/NunitoSans/NunitoSans-Regular.woff') format('woff')
  font-weight: 500

@font-face
  font-family: "NunitoSans"
  src: url("~@fonts/NunitoSans/NunitoSans-Light.ttf") format("truetype"), url('~@fonts/NunitoSans/NunitoSans-Light.woff2') format('woff2'), url('~@fonts/NunitoSans/NunitoSans-Light.woff') format('woff')
  font-weight: lighter

@font-face
  font-family: "NunitoSans"
  src: url("~@fonts/NunitoSans/NunitoSans-Light.ttf") format("truetype"), url('~@fonts/NunitoSans/NunitoSans-Light.woff2') format('woff2'), url('~@fonts/NunitoSans/NunitoSans-Light.woff') format('woff')
  font-weight: 300

@font-face
  font-family: "NunitoSans"
  src: url("~@fonts/NunitoSans/NunitoSans-SemiBold.ttf") format("truetype"), url('~@fonts/NunitoSans/NunitoSans-Bold.woff2') format('woff2'), url('~@fonts/NunitoSans/NunitoSans-Bold.woff') format('woff')
  font-weight: bold

@font-face
  font-family: "NunitoSans"
  src: url("~@fonts/NunitoSans/NunitoSans-ExtraBold.ttf") format("truetype")
  font-weight: 800

@font-face
  font-family: "NotoColorEmoji"
  font-display: swap
  src: url("~@fonts/NotoColorEmoji.ttf")

// ---------------------------------------------------------------------
// Quicksand
// ---------------------------------------------------------------------

@font-face
  font-family: 'Quicksand'
  src: url('~@fonts/Quicksand/Quicksand-Light.woff2') format('woff2'), url('~@fonts/Quicksand/Quicksand-Light.ttf') format('truetype')
  font-weight: 300

@font-face
  font-family: 'Quicksand'
  src: url('~@fonts/Quicksand/Quicksand-Regular.woff2') format('woff2'), url('~@fonts/Quicksand/Quicksand-Regular.ttf') format('truetype')
  font-weight: 400

@font-face
  font-family: 'Quicksand'
  src: url('~@fonts/Quicksand/Quicksand-Medium.woff2') format('woff2'), url('~@fonts/Quicksand/Quicksand-Medium.ttf') format('truetype')
  font-weight: 500

@font-face
  font-family: 'Quicksand'
  src: url('~@fonts/Quicksand/Quicksand-SemiBold.woff2') format('woff2'), url('~@fonts/Quicksand/Quicksand-SemiBold.ttf') format('truetype')
  font-weight: 600

@font-face
  font-family: 'Quicksand'
  src: url('~@fonts/Quicksand/Quicksand-Bold.woff2') format('woff2'), url('~@fonts/Quicksand/Quicksand-Bold.ttf') format('truetype')
  font-weight: 700