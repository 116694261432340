
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
body
  overscroll-behavior-x: none

.planning-component
  @include full-height
  background-color: #ffffff
  position: relative

  $width-sidebar: 20em
  $min-height-item: 3.5em

  display: flex
  flex-direction: column
  flex: 1
  overflow: hidden

  .loaded-data
    position: absolute !important
    top: -0.05em
    left: 0
    right: 0
    height: 0.15em
    z-index: $planning-z-index-loader
    display: flex
    align-items: center
    justify-content: center
    pointer-events: none
    background: $planning-loader-background
    animation: lineLoader 5s linear infinite

  &>.header
    display: flex
    border-bottom: 0.07em solid #E8E8E8
    height: 5em

    @import './zoom'

    .sidebar-head
      min-width: $width-sidebar
      border-right: 0.07em solid #E8E8E8

  &>.body
    flex: 1
    display: flex
    overflow: hidden

    @import './planning-sidebar'
    @import './planning-data'

.tippy-tooltip
  text-align: left
.tippy-tooltip,
.tippy-backdrop
  background-color: rgba(#000, .5)
