
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.scrollbar-wrapper
  overflow-y: auto
  overflow-y: overlay
  -webkit-overflow-scrolling: touch
  scroll-behavior: auto

  // Firefox scrollbar styles
  scrollbar-color: #9BA3AA fade-out(#ffffff, 1) !important
  scrollbar-width: thin !important

  &.orientate-v
    overflow-x: hidden
    overflow-y: auto

  &.orientate-h
    overflow-y: hidden
    overflow-x: auto

  &.orientate-all
    overflow-y: auto
    overflow-x: auto

  &::-webkit-scrollbar
    @include scrollbar-style

  &::-webkit-scrollbar-thumb
    @include scrollbar-thumb-style

.ui.scrollbar-wrapper--dark
  scrollbar-color: #7F848B fade-out(#ffffff, 1) !important

  &::-webkit-scrollbar-thumb
    @include scrollbar-thumb-style-dark
