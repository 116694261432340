
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
// TODO: remove the double selector when pure-marking rules are removed
.ui-edit-field.ui-edit-field
  padding: 0.25em 0.5em

.ui-edit-field
  display: inline-flex
  align-items: center
  border-radius: $edit-field-border-radius

  &:not(.ui-edit-field--readonly):hover
    background-color: $edit-field-background-color-hover

    .ui-edit-field__icon
      opacity: 1

.ui-edit-field__value
  display: inline-block

// the double selector is needed to break the rule: *, *:focus, *:active (see pure-marking)
// TODO: remove the double selector when pure-marking rules are removed
.ui-edit-field__icon.ui-edit-field__icon
  margin-left: 0.5em

.ui-edit-field__icon
  opacity: 0
  transition: opacity 0.2s
