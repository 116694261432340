
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.stick
  display: flex
  align-items: center

  color: white
  background-color: $green-color
  border-radius: 10em
  padding: 0.2em 0.6em
  height: 1.5rem

  .stick__icon
    font-size: 0.63rem // ~10px
    margin-right: 0.5ch

  .stick__text
    font-size: 0.9em

  &.stick--shape_circle
    position: relative
    width: 1.5rem

    .stick__icon
      position: absolute
      top: 50%
      left: 50%
      margin: 0
      transform: translate3d(-50%, -50%, 0)

  &.stick--inline
    display: inline-flex

  &.stick--kind_info-light
    background-color: $info-light-color
    color: $primary-color

  &.stick--kind_blue-light
    background-color: $blue-light-color
    color: white
