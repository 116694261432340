.greed
  display: flex
  pointer-events: none
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  &.day
    .item
      min-width: 7em
      position: relative

      .line-down,
      .line-down-dotted
        top: 0
        bottom: 0
        right: 0
        position: absolute

      .line-down-dotted
        border-left: 0.07em dotted #E8E8E8
        right: 50%

      .line-down
        border-left: 0.07em solid #E8E8E8

  &.week
    .item
      min-width: 12em
      position: relative

      &.holiday
        background-color: $planning-holyday-background-color

      .line-down
        top: 0
        bottom: 0
        right: 0
        position: absolute

      .line-down
        border-left: 0.07em solid #E8E8E8

      .lines-dotted
        top: 0
        bottom: 0
        right: 0
        left: 0
        position: absolute
        display: flex

        .line-down-dotted
          flex-grow: 6
          height: 100%
          border-left: 0.07em dotted #E8E8E8

      &.dst-long-day
        .line-down-dotted
          &:first-child
            flex-grow: 7
      
      &.dst-short-day
        .line-down-dotted
          &:first-child
            flex-grow: 5

  &.month
    .item
      min-width: 3em
      max-width: 3em
      position: relative

      &.holiday
        .line-down
          background-color: $planning-holyday-background-color

      .line-down
        top: 0
        bottom: 0
        left: 0
        right: 0
        position: absolute
        border-left: 0.07em solid #E8E8E8

  &.quarter
    .item
      min-width: 8em
      position: relative

      .lines-dotted
        top: 0
        bottom: 0
        right: 0
        left: 0
        position: absolute
        display: flex

        .line-down-dotted
          flex: 1
          height: 100%
          border-left: 0.07em dotted #E8E8E8

          &.holiday
            background-color: $planning-holyday-background-color
