
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.radio-group
  &:not(:last-child)
    margin-bottom: 0.5em

  &.flexed
    display: flex

    .ui.radio:not(:first-child)
      margin-left: 1.5em
  
.ui.radio
  display: inline-block
 
  .flex-container
    display: flex

  .input-radio
    display: inline-block
    background-image: url(./img/default.svg)
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    line-height: 0em
    margin: auto
    margin-right: 0.5em
    width: 1em
    height: 1em
    cursor: pointer

    &.active
      background-image: url(./img/active.svg) 

  label
    color: #686868
    margin: auto
    flex: 1
    cursor: pointer

  &.theme-blue
    .input-radio
      border: 0.05em solid $input-radio-border-color
      border-radius: 100%
      background-image: none
      background-size: 0.6em

      &.active
        background-image: url(../../../assets/img/check-blue.svg)

  &.success
    .input-radio
      border: 0.05em solid $input-radio-border-color
      border-radius: 100%
      background-image: none
      background-size: 0.6em

      &.active
        background-image: url(../../../assets/img/check-success.svg)

  &.green
    .input-radio
      border: 0.05em solid $input-radio-border-color
      border-radius: 100%
      background-image: none
      background-size: 0.6em

      &.active
        background-image: url(../../../assets/img/check-green.svg)

  &.error
    .input-radio
      border: 0.05em solid $error-color
      border-radius: 100%
      background-image: none
      background-size: 0.6em

  &.ui-radio--size_small
    font-size: 0.9em