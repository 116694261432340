
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
$size-w: 4em

.ui.pick-color
  max-width: ($size-w+0.5)*4
  margin-right: 3em

  input
    display: none

  &>.item
    padding: 1.3em math.div($size-w, 2)

    cursor: pointer
    display: inline-flex
    border-radius: 0.2em
    margin-right: 0.5em
    margin-bottom: 0.5em

    background-position: center
    background-size: 1em
    background-repeat: no-repeat

    &.selected
      background-image: url(./check.svg)
