.zoom
  flex: 1
  overflow-x: auto
  overflow-y: hidden
  color: $primary-color
  position: relative
  display: flex

  @include opacity-scroll

  &>.item
    display: flex
    flex-direction: column

    .title
      flex: 1
      border-bottom: 0.07em solid #E8E8E8
      border-right: 0.07em solid #E8E8E8
      display: flex
      padding: 0.2em

      span
        flex: 1
        border-radius: 0.2em
        cursor: pointer
        transition: 0.2s all
        display: flex
        justify-content: center
        align-items: center
        text-transform: capitalize
        font-weight: bold

        &:hover
          background-color: $planning-button-background-color-hover

    .list
      display: flex

      .item
        border-left: 0.07em solid #E8E8E8

        &.holiday
          background-color: #F8F8F8

        span
          flex: 1
          display: flex
          justify-content: center
          align-items: center
          transition: 0.2s all
          border-radius: 0.2em

          &:not(.no-clickable)
            cursor: pointer

            &:hover
              background-color: $planning-button-background-color-hover

        &.active span
          background-color: #0C8BFF
          color: #ffffff

          &:not(.no-clickable)
            &:hover
              background-color: #6DB9FF

  &.quarter
    .title
      border-left: none
      border-right: 0.07em solid #E8E8E8

    .list>.item
      min-width: 8em
      padding: 0.2em
      border-left: none
      border-right: 0.07em solid #E8E8E8

      span
        padding: 0.4em

        .number
          font-weight: bold
          padding-right: 0.2em

        .dates
          font-size: 0.85em

  &.month
    .list>.item
      min-width: 3em
      max-width: 3em
      width: 3em
      padding: 0.2em

      span
        font-size: 0.9em

  &.weeks
    .list>.item
      min-width: 12em
      padding: 0.2em

      span
        padding: 0.3em
        font-size: 0.9em

    .title
      .month-day
        text-transform: lowercase

  &.days
    .list>.item
      padding: 0.2em
      min-width: 7em

      span
        font-size: 0.9em
        padding: 0.3em

    .title
      .month-day
        text-transform: lowercase
