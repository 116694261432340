/*
 * Pure marking v3.0.0
 * Copyright 2016-2018 Techoof.
 */

/* Basic */
*, 
*:focus,
*:active
    box-sizing: border-box
    outline: none !important
    text-decoration: none
    margin: unset
    padding: unset
    box-shadow: none

body 
    font-size: 18px

.wrapper
    font-size: 1em

a,
button,
.pointer
    cursor: pointer

/* Text position */
.tx-right
    text-align: right


/*
 * Fixes
 */

/* Fix autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus
    border: 0
    -webkit-text-fill-color: #ссс
    -webkit-box-shadow: 0 0 0px 1000px transparent inset
    transition: background-color 5000s ease-in-out 0s
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%)

/* Add the correct display in IE 10-. */
.hidden,
[hidden]
    display: none
