// EXT ICONS
@mixin ext-mix($name, $file: $name)
  .file-ext.#{$name}
    background-image: url("~@img/ext/#{$file}.svg")

.file-ext
  background-repeat: no-repeat
  background-position: center
  background-size: contain
  background-image: url("~@img/ext/default_file.svg")

  @include ext-mix(file-ext, NO-FORMAT)

  // &.3G2,
  // &.3GP,

  // &.ASF,
  // &.AVI,
  // &.FLV,
  // &.M4V,
  // &.MOV,
  // &.MP4,
  // &.MPG,
  // &.RM,
  // &.SRT,
  // &.SWF,
  // &.VOB,
  // &.WMV,
  // &.MPEG
  //   background-image: url("~@img/ext/VIDEO.svg")

@include ext-mix(AIF, AUDIO)
@include ext-mix(IFF, AUDIO)
@include ext-mix(M3U, AUDIO)
@include ext-mix(M4A, AUDIO)
@include ext-mix(MID, AUDIO)
@include ext-mix(MP3, AUDIO)
@include ext-mix(MPA, AUDIO)
@include ext-mix(WAV, AUDIO)
@include ext-mix(WMA, AUDIO)
@include ext-mix(OGG, AUDIO)
@include ext-mix(MPGA, AUDIO)

// @include ext-mix(3G2, VIDEO)
// @include ext-mix(3GP, VIDEO)
@include ext-mix(ASF, VIDEO)
@include ext-mix(AVI, VIDEO)
@include ext-mix(FLV, VIDEO)
@include ext-mix(M4V, VIDEO)
@include ext-mix(MOV, VIDEO)
@include ext-mix(MP4, VIDEO)
@include ext-mix(MPG, VIDEO)
@include ext-mix(RM, VIDEO)
@include ext-mix(SRT, VIDEO)
@include ext-mix(SWF, VIDEO)
@include ext-mix(VOB, VIDEO)
@include ext-mix(WMV, VIDEO)
@include ext-mix(MPEG, VIDEO)

@include ext-mix(CSV)
@include ext-mix(DOCX)
@include ext-mix(PDF)
@include ext-mix(PPT)
@include ext-mix(PPTX)
@include ext-mix(TXT)
@include ext-mix(XLS)
@include ext-mix(XLSX)
@include ext-mix(RAR)
@include ext-mix(ZIP)
@include ext-mix(ODT, TXT)

// LINKS
@include ext-mix(LINK)
@include ext-mix(GOOGLE-DRIVE)
@include ext-mix(GOOGLE-DOCS)
@include ext-mix(GOOGLE-SHEETS)
@include ext-mix(GOOGLE-SLIDES)
@include ext-mix(DROPBOX)
@include ext-mix(ONE-DRIVE)

// MAIL
@include ext-mix(MSG, MAIL)
@include ext-mix(EML, MAIL)

// END EXT ICONS
