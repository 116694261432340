
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.media-panel
  @include fill-height

  &>.header
    &>.control-panel
      background-color: #E8EFF5
      display: flex
      padding: 0em 0.5em
      height: 3.5em

      &>.header,
      &>.body,
      &>.footer
        display: flex
        align-items: center

      &>.body
        flex: 1

      .control-view
        display: flex
        cursor: pointer
        padding: 0.5em 0.6em
        border-radius: 0.2em

        &:hover
          background-color: #CDD8E1

        .ui.svg
          pointer-events: none

      .control-item
        display: flex
        padding: 0.5em 0.8em
        border-radius: 0.2em
        cursor: pointer
        transition: 0.2s
        margin: auto 0em
        position: relative

        &>.ui.input.shadow
          z-index: 1

        .ui.svg,
        .placeholder
          pointer-events: none

        &:hover
          background-color: #CDD8E1

        &:not(:last-child)
          margin-right: 0.5em

        .placeholder
          white-space: nowrap
          margin: auto 0em
          font-size: 0.9em
          color: $primary-color
        
        &>.thumb
          margin: auto 0.5em auto 0em

      .control-view,
      .control-item
        &>.arrow
          font-size: 0.6em
          margin: auto 0em auto 0.8em

  &>.body
    @include fill-height
    flex: 1

    @import './breadcrumbs'

    .preloader
      padding: 1em

      .item
        font-size: 2em

    .preloader.line
      .item
        margin-bottom: 0.5em

        .square
          margin-right: 0.5em

    .preloader.block
      display: flex
      flex-wrap: wrap
      margin-right: -1em

      .item
        .square
          margin-right: 0em
          font-size: 3em


        margin-right: 1em
        margin-bottom: 1em
    .select-all 
      padding-left: 1.2em
      display: flex
      flex-direction: row-reverse
      justify-content: flex-end
      color: $primary-color
      padding-bottom: 0.5em

    &>.header
      padding: 1em
      display: flex

      .title
        margin: auto 0em
        color: $primary-color
        white-space: nowrap
        flex: 1

        span
          padding: 0.2em 0.5em
          margin-left: -0.5em
          font-size: 1.15em
          border-radius: 0.2em
          transition: 0.2s

          &:hover
            background-color: #E9EFF4

      .count-selected
        margin: auto 0.8em auto auto
        display: flex
        cursor: pointer

        span
          margin: auto 0.3em
          color: $primary-color
          white-space: nowrap

        .ui.svg
          margin: auto 0em
          font-size: 0.7em

      .search-input
        margin: auto 0em
        display: flex
        border-radius: 10em
        position: relative
        border: 0.07em solid #7394AB
        font-size: 0.8em
        width: 9em
        transition: 0.2s all

        .ui.svg
          margin: auto 0.5em

        .search
          margin-left: 1em
          margin-right: 0.8em

        .clear
          font-size: 1.2em

        .btn-clear
          display: flex
          margin: auto 0em

        input
          border: unset
          background: unset
          padding: 0.5em 0em
          color: #7394AB
          flex: 1
          padding-right: 0.8em
          width: 100%
      
      &:not(.active-search)
        .search-input .clear
          display: none

      &.active-search
        flex-direction: column-reverse

        .title
          display: none

        .search-input
          width: 100%

        .count-selected
          padding-top: 0.8em

    &>.body
      @include fill-height
      position: relative
      flex: 1
      padding-bottom: 1em

      @import './add-file-zone'

      .line-table-header
        display: flex
        width: 100%
        padding: 0.5em 0em
        font-weight: bold
        color: $primary-color
        border-bottom: 0.07em solid #CBD2D8

        .check
          width: 3em
          padding-left: 1em
          transition: 0.2s opacity

          .ui.checkbox
            margin: auto 0em
            font-size: 0.9em

        .preview
          width: 3em
          display: flex
          padding-left: 0.5em

          .ui.svg
            margin: auto 0em
            font-size: 1.1em

        .name,
        .time,
        .size
          display: flex

          span
            font-size: 0.9em

        .name
          flex: 1

        .time
          width: 7em

        .size
          width: 4em

        .actions
          width: 3em

        .time,
        .size
          padding-left: .5em

        &:not(:hover):not(.selected)
          .check
            opacity: 0

      &>.empty-media,
      &>.empty-search
        text-align: center
        padding: 4em 0em
        color: $primary-color

        .description
          font-size: 0.9em

      &>.empty-search
        .title
          font-size: 1.2em
          font-weight: bold
          padding-bottom: 0.3em

      .list-media
        display: flex
        flex-wrap: wrap

        @import "./media-item"

        &>.block
          padding: 0em 0.5em

.splitpanes
  height: auto !important
.splitpanes__pane
  background-color: transparent !important
.splitpanes__splitter
  border-left: none !important
  width: auto !important
  background-color: transparent !important
  &:before,
  &:after
    background-color: transparent !important
    width: 5px !important
    height: 100% !important
  &:after
    border-left: 1px solid #CBD2D8 !important
  &:nth-child(3n):after
    border-left: none !important
.name-pane
  display: flex

.name-column,
.size-column
  display: flex
  overflow: hidden
.time-column
  overflow: hidden

.media-panel__error-block
  border: 1px solid currentColor
  border-radius: 6px
  background-color: mix($color-danger-secondary, $white-color, 10)
  color: $color-accent-orange-600
  font-size: 14px
  display: flex
  gap: 15px
  flex-direction: column
  align-items: center
  text-align: center

  &.media-panel__error-block
    padding: 40px 20px
    margin: 0 15px
