
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
// Source: src/views/RegistrationTypes/styles/company-logo-col.sass

.avatar-select
  &>.header
    text-align: center
    font-size: 1.1em
    font-weight: bold
    color: $primary-color

  &>.footer
    display: flex
    flex-direction: column
    align-items: center

    .btn
      color: #7F848B
      border-bottom: 0.05em solid #7F848B
      padding-bottom: 0.1em
      position: relative

  &>.body
    padding: 1em 0em
    display: flex
    flex-direction: column
    align-items: center

    .change-avatar
      position: relative
      display: flex

      .ui.avatar
        box-shadow: unset

      &:not(.no-avatar)
        &:hover .change
          opacity: 1

        .img-wrapper
          background-size: contain !important
          background-repeat: no-repeat
          background-color: #ffffff
          border-radius: 0em

      &.no-avatar
        .ui.avatar
          opacity: 0

        .change
          opacity: 1
      
      .change
        border: unset
        background: unset
        background-color: $avatar-select-placeholder-background-color
        background-image: url(~@img/upload-avatar-camera.svg)
        background-position: center 2.2em
        background-repeat: no-repeat
        background-size: 2.2em
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        color: #ffffff
        width: 100%
        padding-top: 2.5em
        opacity: 0
        transition: 0.2s opacity
