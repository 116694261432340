@mixin color-builder($borcolor, $bgcolor, $txcolor)
  &
    border-color: $borcolor
    background-color: $bgcolor
    color: $txcolor
  
&
  &.wide
    padding-left: 1.5em
    padding-right: 1.5em

  &.small
    font-size: 0.8em
    padding: 0.5em 1em
    font-weight: 500

  &.medium
    font-size: 1.1em

    &.square
      padding: 0.5em

  &.big
    height: 2.6em
    font-size: 1.2em
    padding: 0.5em 1em

    &.wide
      padding-left: 2em
      padding-right: 2em

    .ui.svg
      font-size: 0.8em
      margin-right: 0.5em
      margin-left: -0.2em

  &.border-input
    border-color: #D1D7DD

    &:hover
      border-color: $primary-color
      color: $primary-color

  &.white
    @include color-builder(#ffffff, #ffffff, $primary-color)

  &.o-white-grey
    @include color-builder(#C9D2D9, #ffffff, $primary-color)

    &:hover
      @include color-builder($primary-color, #ffffff, $primary-color)

  &.light
    @include color-builder($primary-color, #ffffff, $primary-color)

  &.success
    @include color-builder($control-green-color, $control-green-color, #ffffff)

    &:hover
      opacity: 0.8

  &.green
    @include color-builder(#00B452, #00B452, #ffffff)

    &:hover
      opacity: 0.8

  &.dodger-light-blue
    @include color-builder(#E6F4FF, #E6F4FF, #0C8BFF)

  &.bali-hai
    @include color-builder($bali-hai-color, $bali-hai-color, #ffffff)

  &.bali-hai-o
    @include color-builder($bali-hai-color, #ffffff, $bali-hai-color)

  &.light-gray
    @include color-builder(#ffffff, #ffffff, $primary-color)
    font-weight: 700

    &:hover
      background-color: $lavander-color
      border-color: $lavander-color

  &.danger-o
    @include color-builder($error-color, #ffffff, $error-color)

  &.danger-link
    @include color-builder(#ffffff, #ffffff, $error-color)

  &.link
    @include color-builder(rgba(white, 0), rgba(white, 0), $blue-color)
    font-weight: 700
    padding: 0
