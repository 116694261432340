
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.select-status
  position: relative

  .select-status__icon
    position: absolute
    display: flex
    right: 0.9em
    top: 0.9em

  &.disabled
    pointer-events: none

    &>.header .arrows
      display: none

  &.archived
    &>.header
      .name
        // font-style: italic
        // color: #80848B

  &>.header
    border: $input-border-style
    border-radius: 0.3rem
    background: #ffffff
    cursor: pointer
    transition: 0.2s

    .item
      display: flex
      padding: 0.7em 3em 0.7em 1em

      .name
        font-size: 0.9em
        color: $primary-color

      &.selected .name
        color: $primary-color

  &>.footer
    .list
      position: absolute
      transform-origin: top
      transition: transform .2s ease-in-out

      max-height: 13em
      background: #ffffff
      position: absolute
      top: 0
      left: 0
      right: 0
      z-index: 2
      border: $input-border-style

      &[data-placement^=bottom]
        border-top: unset
        border-bottom-left-radius: 0.3rem
        border-bottom-right-radius: 0.3rem

      &[data-placement^=top]
        border-bottom: unset
        border-top-left-radius: 0.3rem
        border-top-right-radius: 0.3rem

      .item
        display: flex
        padding: 0.7em 1em
        font-size: 0.9em
        cursor: pointer
        font-weight: 600
        color: $primary-color

        &:nth-child(even)
          background-color: #F6F9FB
      
        &.create
          background-color: #ffffff
          color: $green-color

          .ui.svg
            width: 0.7em !important
            height: 0.7em !important
            margin: auto 0.7em auto 0em

  .item
    .name
      @include text-ellipsis
      flex: 1
      transition: 0.2s color

  .select-status__list-item
    &:hover
      .ui.svg,
      .name
        opacity: 0.7

  &[data-placement^=bottom]
    &>.header
      border-bottom-left-radius: unset
      border-bottom-right-radius: unset

  &[data-placement^=top]
    &>.header
      border-top-left-radius: unset
      border-top-right-radius: unset