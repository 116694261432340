
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.email-input
  width: 100%
  .ui.input
    input
      border: unset
      background: unset

    min-width: 5em
    flex: 1
    height: 2em
    margin: auto 0em

    &.error
      color: $error-color
  
  .list.selected
    max-height: 8em
    border: 0.05em solid #B2C0CC
    padding: 0em 0.5em 0.5em 0.5em
    border-radius: 0.3rem
    font-family: 'NunitoSans'
    color: #34335C
    display: flex
    flex-wrap: wrap
    cursor: text

    &.error
      border-color: $error-color

    .item
      border: $input-borders solid #34335C
      margin: 0.5em 0.5em 0em 0em
      border-radius: 0.3rem
      overflow: hidden

      &>.body
        display: flex
        height: 2em

        .name
          @include text-ellipsis
          display: inline-block
          padding: 0em 0.5em
          margin: auto 0em
          flex: 1

        .cross
          border: unset
          background: unset
          background-image: url(~@img/cross-dark.svg)
          background-size: contain
          background-repeat: no-repeat
          background-position: center
          width: 0.6em
          height: 0.6em
          margin: auto 0.5em auto 0
