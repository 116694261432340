
          @use "sass:math";
          @use "sass:color";
          @use "sass:list";
          @use "sass:selector";
          @use "@/assets/sass/config.sass" as *;
        

.checklist-item-pictograms {
  display: flex;
  align-items: center;
  // TODO: remove !important declaration when pure-marking rules are removed
  margin-bottom: auto !important;
  // TODO: remove !important declaration when pure-marking rules are removed
  padding-top: 0.5em !important;
}

.checklist-item-pictograms__item {
  margin: auto 0em;
  margin-left: 0.7em;
  display: flex;
  align-items: center;
}

.checklist-item-pictograms__counter {
  color: $primary-color;
  margin-top: 0.1em;
  margin-left: 0.35em;
  line-height: 1;
  font-size: 0.9em;
}

.checklist-item-pictograms__counter--kind_success {
  color: $green-color;
}

.checklist-item-pictograms--is_inline {
  padding: 0 !important;
  margin-bottom: 0 !important;
  cursor: pointer;

  .checklist-item-pictograms__item {
    margin: 0;
    gap: 0.3em;
  }

  .checklist-item-pictograms__counter {
    margin: 0;
    font-size: unset;
  }

}
