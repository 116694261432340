
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.rounded-checkbox
  & svg 
    width: 11px !important
  &__round
    background-color: #fff
    border: 2px solid #DCDEE1
    border-radius: 50%
    cursor: pointer
    content: ""
    height: 1.4em
    width: 1.4em
    display: flex
    align-items: center
    justify-content: center
    &:hover::after
      display: block
    &:after
      content: "\002B"
      position: relative
      font-size: 1.5em
      right: 0.05em
      color: #DCDEE1
      font-weight: bold
      display: none
  input[type="checkbox"]
    display: none

    &:checked + .rounded-checkbox__round
      background-color: #66bb6a
      border-color: #66bb6a
      &:after
        display: none


  &.disabled
    opacity: 0.2
