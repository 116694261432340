
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.input-placeholder
  .placeholder
    color: $primary-color
    font-size: 0.8em
    padding-bottom: 0.2em

.ui.inputs-group
  &:not(:last-child)
    margin-bottom: 0.5em

  &:not(.flexed)
    .ui.input,
    .ui.input-placeholder
      &:not(:last-child)
        margin-bottom: 0.5em

  &.flexed
    display: flex

    .ui.input,
    .ui.input-placeholder
      flex: 1

      &:not(:last-child)
        margin-right: 0.7em

.ui.input
  width: 100%
  position: relative

  &.with-after input
    padding-left: 3.5em

  .after-icon
    border-radius: 0.3rem 0rem 0rem 0.3rem
    position: absolute
    top: 0.07em
    bottom: 0.07em
    left: 0.07em
    display: flex
    align-items: center
    justify-content: center
    width: 2.5em
    background-color: #F2F5F8
    border-right: $input-border-style

  &.error
    input,
    textarea
      border-color: $error-color
      color: $error-color

  &.icons
    position: relative

    input
      padding-left: 2.8em

    &:before
      content: " "
      height: 100%
      width: 2.8em
      position: absolute
      background-position: center
      background-repeat: no-repeat

    &.project:before
      background-image: url(~@img/icon-projects.svg)
      background-size: 1.3em

    &.search:before
      background-image: url(~@img/find-grey.svg)
      background-size: 0.9em


  .file-upload
    position: relative
    border: $input-borders dashed $theme-color
    border-radius: 0.2rem

    input
      all: unset
      opacity: 0
      visibility: hidden
      position: absolute

    .placeholder
      padding: 2.5em 0em
      text-align: center
      color: $theme-color
      font-size: 0.9em

      &:before
        content: ''
        padding: 1em
        background-image: url(./icon-upload.svg)
        background-repeat: no-repeat
        background-position: center left
        background-size: 1.4em

  input,
  textarea
    background-color: #ffffff
    display: block
    width: 100%
    border: $input-border-style
    padding: 0.7em 1em
    border-radius: 0.3rem
    font-family: 'NunitoSans'
    font-size: 0.9em
    color: #34335C

  textarea
    min-height: 6em
    min-width: 100%
    max-width: 100%

    &.auto-resize
      overflow: hidden

    overflow-y: scroll
    overflow-y: overlay
    -webkit-overflow-scrolling: touch

    &::-webkit-scrollbar
      width: 0.4em
      height: 0.4em

    &::-webkit-scrollbar-thumb
      background-color: #838c95
      box-shadow: inset 0px 0px 120px 0px rgba(235, 238, 243, 0.25)
      border-radius: 0.2em

  &.shadow
    &,
    .file-upload
      position: absolute
      top: 0
      bottom: 0
      right: 0
      left: 0
      cursor: pointer

    .file-upload
      border: unset

    .placeholder
      display: none

  &.filled
    textarea
      background-color: #F1F7FD
      border-color: #F1F7FD

  &.size_small
    input
      padding: .4em 1em


  &.ui-input--kind_gray-text
    input
      color: $gray-color

  &.ui-input--kind_blue-text
    input
      color: $theme-blue-color
