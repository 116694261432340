
          @use "sass:math";
          @use "sass:color";
          @use "sass:list";
          @use "sass:selector";
          @use "@/assets/sass/config.sass" as *;
        

.formula-input__tip-wrapper {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  padding: 0.2em 0;
  font-size: 0.8em;
}

.formula-input__tip {
  border-radius: 0.2em;
  background-color: $planning-tooltip-background;
  padding: 0.2em 0.4em;
}
