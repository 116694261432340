
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.custom-select 
  position: relative
  width: 100%
  text-align: left
  outline: none
  font-size: 0.9em
  line-height: 1.45em
  cursor: pointer

.custom-select__arrow
  position: absolute
  top: 50%
  right: 0.9em
  transform: translateY(-50%) rotate(180deg)
  pointer-events: none

.custom-select__selected
  height: 2.9175em
  background-color: #ffffff
  display: flex
  align-items: center
  width: 100%
  border: $input-border-style
  // TODO: remove !important declaration when pure-marking rules are removed
  padding: 0.7em 3em 0.7em 1em !important
  border-radius: 0.3rem
  font-family: 'NunitoSans', sans-serif
  color: #34335C
  cursor: pointer

.custom-select__empty
  height: 1.45em

.custom-select__items
  position: absolute
  top: 0
  left: 0
  right: 0
  max-height: 15em
  border-radius: 0.3rem
  font-family: 'NunitoSans', sans-serif
  color: #34335C
  background-color: #ffffff
  overflow-x: hidden
  overflow-y: auto
  z-index: 1
  box-shadow: 0 0.3em 0.3em #e9eff4

.custom-select__item
  display: flex
  align-items: center
  // TODO: remove !important declaration when pure-marking rules are removed
  padding: 0.7em 1em !important
  cursor: pointer
  user-select: none

  &:hover 
    background-color: $registrations-table-background-color-hover

.custom-select__icon
  font-size: 1.78em // ~24px
  margin-right: 0.3em

.custom-select__image
  width: 1.3em
  height: 1.3em
  display: block

.custom-select__value
  display: flex
  align-items: center

.hide 
  display: none
