
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.find-list
  display: flex
  position: relative
  width: 100%
  height: 2.7em
  min-width: 0

  &.drop
    &>.header
      border-radius: 0.3rem 0.3rem 0rem 0rem
      border-bottom: none

  &>.header
    background-color: #ffffff
    display: flex
    flex: 1
    align-items: center
    border: $input-border-style
    padding: 0.7em 1em
    border-radius: 0.3rem
    font-family: 'NunitoSans'
    font-size: 0.9em
    color: #34335C
    cursor: pointer
    overflow: hidden

    .placeholder
      flex: 1
      @include text-ellipsis
      &.empty
        color: $placeholder-color

      .text-link-blue
        cursor: pointer
        color: $theme-blue-color
        text-decoration: underline

  &>.body
    background: #ffffff
    position: absolute
    z-index: 2
    top: 100%
    left: 0
    right: 0
    border: $input-border-style
    border-radius: 0em 0em 0.3rem 0.3rem
    box-shadow: 0em 0em 0.5em 0em fade-out(#000000, 0.9)

    .find
      background-color: #ECF1F6
      padding: 0em 1em
      display: flex
      align-items: center

      input
        border: unset
        background: unset
        padding: 0.7em
        color: #7A93A8
        flex: 1

    .list
      max-height: 10em

      .item
        padding: 0.5em 1em
        font-size: 0.9em
        color: $primary-color
        cursor: pointer
        transition: 0.2s all
        @include text-ellipsis
        
        &:hover
          opacity: 0.7
