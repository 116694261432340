
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.checklists-component
  @import './FormAddChecklistItem'

  .chat-controls
    > .ui.button
      white-space: pre-line

  &>.footer
    display: flex

    .btn-create-checklist
      display: flex
      align-items: center
      margin-top: 1.5em
      margin-left: 2em
      cursor: pointer

      .ui.button
        font-size: 0.7em

      span
        padding-left: 0.7em
        font-size: 1em
        color: $bali-hai-color


  .form.add
    .ui.form>.body
      padding-bottom: 0.1em !important
