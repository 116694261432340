
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
.ui.line-loader
  position: relative

  .line-l
    position: absolute !important
    top: 0em
    left: 0
    right: 0
    height: 0.15em
    z-index: 20
    display: flex
    align-items: center
    justify-content: center
    pointer-events: none
    background: $theme-blue-color
    animation: loadedLineAnimation 5s linear infinite

    @keyframes loadedLineAnimation
      0%
        transform: translate(-100%, 0)

      50%
        transform: translate(100%, 0)

      100%
        transform: translate(-100%, 0)