.checklist-item
  &:not(:last-child)
    margin-bottom: 0.5em

  &:hover .border-container
    background-color: $checklist-item-background-color-hover !important

  &>.border-container
    border: 0.07em solid #C3CFD9
    border-radius: 0.3em
    background-color: $checklist-item-background-color
    transition: background-color .2s

    &>.footer
      display: flex
      padding-left: 2.3em
      align-items: center
      flex-wrap: wrap

      .duedate-task
        font-size: 0.9em
        margin-right: 0.5em
        margin-bottom: 0.5em

        &>.body
          display: flex

          .check-date
            display: flex
            border-radius: 0.3rem
            border: $input-border-style
            background-color: #F2F5F8
            padding: 0.5em 0.7em
            color: $primary-color
            align-items: center

            .clock-icon
              margin-right: 0.4em
              margin-top: 0.1em
              margin-bottom: auto

            .text-data
              display: flex
              flex-wrap: wrap

            &:not(.completed)
              &.warning
                background-color: #FFF3CE
                border-color: #FED54A

                .ui.checkbox .input-checkbox
                  border-color: #FED54A

              &.overdue
                background-color: #EFD1D1
                border-color: #EA7565

                .ui.checkbox .input-checkbox
                  border-color: #EA7565

            .item
              white-space: nowrap

            .item,
            .separator,
            .text-data
              pointer-events: none

            .separator
              padding: 0em 0.3em

      .users-in-task
        padding: 0em 0.5em 0.5em 0em
        display: flex
        flex-wrap: wrap

        .ui.avatar
          margin-right: 0.3em

    &>.body
      padding: 0em 0.5em

      &>.ui.form.textarea
        background: unset

        &>.body
          background: unset

        input,
        textarea
          background: transparent
          min-height: unset
          cursor: pointer

          &:not(.shadow)
            height: 0em

        .controls
          transition: 0.2s opacity
          display: flex
          margin-bottom: auto
          padding-top: 0.5em

          .btn
            position: relative
            display: flex

          .ui.svg
            margin: auto 0em
            margin-left: 0.7em
            cursor: pointer

        .ui.checkbox .input-checkbox
          margin: 0.5em 0.2em 0.5em 0em

        &>.footer
          margin-bottom: 1em

        .ui.input
          input,
          textarea
            padding: 0.4em 0.5em
            border-color: transparent

  &.checked
    &>.border-container
      background-color: $checklist-item-background-color-checked

      &>.body
        textarea
          text-decoration: line-through
          color: #7F848B
